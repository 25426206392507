import React, { useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { transactionDeals } from '../../../../../data/constants'

import "./styles.scss"

const DealCostModal = ({ onSave, data, onClose }) => {
    const [dealCost, setDealCost] = useState("")
    const { t } = useTranslation();

    useEffect(() => {
        const { dealCost } = data
        dealCost && setDealCost(dealCost)
    }, []);

    // const handleChangeCost = (value) => {
    //     const isValid = noZeroFirstDecimalNumber(value)
    //     if (isValid || value === "") {
    //         setDealCost(value)
    //     }
    // }

    const save = () => {
        const data = { dealCost }
        onSave(data)
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDealCost((event.target as HTMLInputElement).value);
    };

    return (
        <div className="traffic-container">
            <FormControl>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    className="traffic-radio"
                    value={dealCost}
                >
                    {transactionDeals.map(({ id, name }) => {
                        return (
                            <FormControlLabel
                                key={id}
                                value={id}
                                control={<Radio />}
                                label={t(name)}
                                onChange={handleChange}
                            />
                        )
                    })}
                </RadioGroup>
            </FormControl>

            <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                    onClick={() => onClose()}
                    variant="text"
                    sx={{ 
                        marginRight: '10px',
                    }} >
                    {t('Cancel')}
                </Button>
                <Button
                    onClick={() => save()}
                    variant="contained">
                    {t('Save')}
                </Button>
            </Box>
        </div>
    )
};

export default DealCostModal;