
import React from 'react';

export const ErrorIcon = () => {
    return (
        <div className="logo">
            <svg width="92" height="92" viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="x-octagon 1">
                <path id="Vector" d="M30.1293 7.6665H61.8693L84.3327 30.1298V61.8698L61.8693 84.3332H30.1293L7.66602 61.8698V30.1298L30.1293 7.6665Z" stroke="#E23D3D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Vector_2" d="M57.5 34.5L34.5 57.5" stroke="#E23D3D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Vector_3" d="M34.5 34.5L57.5 57.5" stroke="#E23D3D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
            </svg>
        </div>

    );
};