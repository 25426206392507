import { RootState } from '../../interfaces'

const getMainReducerState = (state: RootState) => {
    return state?.mainReducer;
};

export const getUserDataState = (state: RootState) => {
    return getMainReducerState(state).userData || [];
};

export const getUserData = (state: RootState) => {
    return getMainReducerState(state).userData;
};

export const getIsLoading = (state: RootState) => {
    return getMainReducerState(state).isLoading;
};

export const getToken = (state: RootState) => {
    return getMainReducerState(state).token;
};

export const getEmployment = (state) => {
    return getMainReducerState(state).employment;
};

export const getDoubleFetch = (state) => {
    return getMainReducerState(state).doubleFetch;
};

export const getPage = (state) => {
    return getMainReducerState(state).page;
};

export const getAdressState = (state: RootState) => {
    return getMainReducerState(state).adress;
};

export const getCameraScreenShot = (state) => {
    return getMainReducerState(state).cameraScreenShot;
};
