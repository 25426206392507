import React from 'react';
import { IFormSectionProps } from "./types"
import classNames from "classnames"
import './styles.scss';

function FormSection({
    title,
    children,
    pb0 = false,
    disabled,
    id,
    noPadding,
    subTitle,
    contentCentered = false,
    noFullHeight = false,
    hideTitleAndSubtitle = false }: IFormSectionProps) {

    return (
        <div
            {...{ id }}
            className={classNames({
                'form-section': true,
                'disabled': disabled,
                'parent-content-centered': contentCentered,
                'no-full-height': noFullHeight
            })}>

            <div
                className={classNames({
                    'pb0': pb0,
                    'no-padding': noPadding,
                    'form-section-content': noPadding,
                    'content-centered': contentCentered
                })}
            >
                {title && !hideTitleAndSubtitle && <div className="section-title">{title}</div>}
                {subTitle && !hideTitleAndSubtitle && <div className="section-subtitle">{subTitle}</div>}
                {children}
            </div>
        </div>
    );
};

export default FormSection;
