import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const RadioComponent = ({ questions, values, handleChange, t }) => {
  
    return questions && questions.map(({ id, title, questions }, i) => {
        return (
            <FormControl className="form-control-modal" key={i}>
                <FormLabel id="demo-controlled-radio-buttons-group">{title}</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={values[id]}
                    onChange={(e) => handleChange(e, id)}
                >

                    {questions && questions.map(({ question }, index) => {
                        return (
                            <FormControlLabel value={t(question)} control={<Radio />} label={t(question)} key={index} />
                        );
                    })}
                </RadioGroup>
            </FormControl>
        );
    });
};

export default RadioComponent;