import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { getDetailsState } from '../../../services/selectors/detailsSelector';
import { questions } from '../../../data/constants'
import { saveFatcaValues } from '../../../services/actionCreators/mainActionCreator';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormSection from "../../../infrastructure/section/form-section"
import ActionButtons from "../../../infrastructure/section/action-buttons-section"

import './styles.scss'

const StepFatca = ({ handleNext, handleBack, isAdaptive }) => {
    const data = useSelector(getDetailsState);
    const [questionChecked, setQuestionsChecked] = useState({ ...data.values })

    const dispatch = useDispatch();
    const { t } = useTranslation();


    const handleChange = (value, id) => {
        const data = {
            ...questionChecked,
            [id]: value
        }
        setQuestionsChecked(data)
        dispatch(saveFatcaValues(data))
    }

    const renderQuestions = () => {
        const { t } = useTranslation();
        return questions && questions.map(({ id, title, questions }, i) => {
            return (
                <FormControl className="form-control-modal" key={i}>
                    <FormLabel id="demo-controlled-radio-buttons-group" className="question-title">{t(title)}</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={questionChecked[id]}
                        onChange={(e) => handleChange(e.target.value, id)}
                        className="question-radio-wrapper"
                    >

                        {questions && questions.map(({ question }, index) => {
                            return (
                                <FormControlLabel value={question} control={<Radio />} checked={questionChecked[id] === question} label={t(question)} key={index}                                     
                                sx={{ 
                                    '& label:first-child': {
                                        marginRight: '28px'
                                    },
                                }}/>
                            );
                        })}
                    </RadioGroup>
                </FormControl>
            );
        });
    }

    const disabled = Object.keys(questionChecked).length !== 6

    return (
        <div className="step-container">
            <FormSection id="step-seven" title={t('Step_8_title')}>
                <div className="questions-container">
                    {renderQuestions()}
                </div>
            </FormSection>

            <ActionButtons
                {...{
                    disabled,
                    handleNext,
                    handleBack,
                    isAdaptive
                }}
                backBtnLabel={t('Back_step')}
                nextBtnLabel={t('Next_step')}
            />

        </div>
    )
}

export default StepFatca