import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { employmentList } from "../../../data/constants"
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormSection from "../../../infrastructure/section/form-section"
import ActionButtons from "../../../infrastructure/section/action-buttons-section"
import {
    editWorkPlace
} from '../../../services/actionCreators/mainActionCreator';
import { checkValidDate } from "../../../data/constants"
import { dataFormatInput } from '../../../hooks';
import './styles.scss'
import { FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';

const StepWork = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const {
        userEmployment: {
            workPlaces: {
                employmentStatus,
                company,
                jobTitle,
                startWorkDate = null,
                isOfficial
            } },
        handleNext,
        handleBack,
        isAdaptive } = props;
    const onChange = (value, stateName) => {
        dispatch(editWorkPlace(stateName, value))
    };

    const renderMenuItems = (list, needToTranslate = false) => {
        const items = list.map(({ id, name }, index) => {
            return (
                <MenuItem value={name} key={index}>{needToTranslate ? t(name) : name}</MenuItem>
            )
        })

        return items
    }

    const handleChange = (newValue: Date | null) => {
        newValue && onChange(newValue, "startWorkDate")
    };

    const validDate = checkValidDate(startWorkDate)
    const disabled = company === "" || jobTitle === "" || !validDate

    return (
        <div className="step-container">

            <FormSection id="step-five" title={t('Step_6_title')}>

                <div className="phone-information">
                    <div className='m-b-16'>
                        <TextField
                            className="text-input"
                            fullWidth
                            id="outlined-basic"
                            label={t('Company')}
                            variant="outlined"
                            value={company}
                            onChange={(e) => onChange(e.target.value, 'company')}
                            onKeyDown={(event) => {
                                event.stopPropagation();
                            }}
                        />
                    </div>
                    <div className="phone-number m-b-16">
                        <TextField
                            className="text-input"
                            fullWidth
                            id="outlined-basic"
                            label={t('Job_title')}
                            value={jobTitle}
                            onChange={(e) => onChange(e.target.value, 'jobTitle')}
                            onKeyDown={(event) => {
                                event.stopPropagation();
                            }}
                        />
                    </div>

                    <div className="row-container">
                        <div className="max-w-50 m-r-16 m-b-16">
                            <FormControl fullWidth className="text-input">
                                <InputLabel id="demo-simple-select-label">{t('Employment')}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={employmentStatus}
                                    label={t('Employment')}
                                    onChange={(e) => onChange(e.target.value, 'employmentStatus')}
                                >
                                    {renderMenuItems(employmentList, true)}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="max-w-50 m-b-16">
                            <LocalizationProvider dateAdapter={AdapterDateFns} >
                                <Stack spacing={3}>
                                    {startWorkDate ?
                                        <DesktopDatePicker
                                            format={dataFormatInput}
                                            className="text-input"
                                            label={t('Start_date')}
                                            value={new Date(startWorkDate)}
                                            onChange={handleChange}
                                            maxDate={new Date()}
                                            disableFuture
                                        />
                                        :
                                        <DesktopDatePicker
                                            format={dataFormatInput}
                                            className="text-input"
                                            label={t('Start_date')}
                                            onChange={handleChange}
                                            maxDate={new Date()}
                                            disableFuture
                                        />
                                    }
                                </Stack>
                            </LocalizationProvider>
                        </div>
                    </div>

                    <FormControl className="form-control-modal">
                        <FormLabel id="demo-controlled-radio-buttons-group" className="question-title">{t('Is_job_official')}</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={isOfficial}
                            onChange={(e) => onChange(e.target.value, 'isOfficial')}
                        >
                            <FormControlLabel value={true} checked={isOfficial === "true"} control={<Radio />} label={t('Yes')} />
                            <FormControlLabel value={false} checked={isOfficial === "false"} control={<Radio />} label={t('No')} />
                        </RadioGroup>
                    </FormControl>
                </div>
            </FormSection>

            <ActionButtons
                {...{
                    disabled,
                    handleNext,
                    handleBack,
                    isAdaptive
                }}
                backBtnLabel={t('Back_step')}
                nextBtnLabel={t('Next_step')}
            />
        </div>
    )
}

export default StepWork