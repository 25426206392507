import React from 'react';
import "./styles.scss"

export const SuccessIcon = () => {
    return (
        <div className="success-icon">
            <svg width="92" height="92" viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M84.3347 42.4731V45.9998C84.3299 54.2661 81.6532 62.3094 76.7037 68.9301C71.7543 75.5509 64.7972 80.3943 56.8702 82.7381C48.9431 85.0819 40.4708 84.8004 32.7168 81.9357C24.9627 79.071 18.3425 73.7765 13.8433 66.8419C9.34416 59.9073 7.20717 51.7041 7.75106 43.4557C8.29496 35.2073 11.4906 27.3557 16.8614 21.0719C22.2322 14.7881 29.4903 10.4088 37.5534 8.58703C45.6164 6.76529 54.0523 7.59876 61.603 10.9631" stroke="#20B053" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M84.3333 15.333L46 53.7047L34.5 42.2047" stroke="#20B053" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

        </div>

    );
};