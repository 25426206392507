import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { sourcesOfFunds } from '../../../../../data/constants'
import { useTranslation } from 'react-i18next';

const SourceOfFunds = ({ data, onSave, onClose }) => {
    const [checkedFunds, setCheckedFunds] = useState([]);
    const { t } = useTranslation();
    
    useEffect(() => {
        const { checkedFunds } = data;

        checkedFunds && setCheckedFunds(checkedFunds);
    }, []);

    const handleToggle = (value: number) => () => {
        const checkedIndex = checkedFunds.indexOf(value);
        const arr = [...checkedFunds];

        checkedIndex === -1 ? arr.push(value) : arr.splice(checkedIndex, 1);
        setCheckedFunds(arr);
    };

    const save = () => {
        const data = {
            checkedFunds,
        };
        onSave(data);
    };

    return (
        <>
            <List className="source-funds-list" dense>
                {sourcesOfFunds.map(({ name, id }) => {
                    const labelId = `checkbox-list-secondary-label-${id}`;
                    return (
                        <ListItem
                            key={id}
                            className="list-item"
                        >
                            <Checkbox
                                edge="end"
                                onChange={handleToggle(id)}
                                checked={checkedFunds.indexOf(id) !== -1}
                                inputProps={{ 'aria-labelledby': labelId }}
                                sx={{ 
                                    padding: '0px',
                                    marginRight: '12px',
                                }}    
                            />
                            <ListItemButton disableGutters className='list-modal-button' onClick={handleToggle(id)}>
                                <ListItemText 
                                    id={labelId} 
                                    primary={t(name)} 
                                    classes={{ primary: 'list-text' }} 
                                    sx={{ 
                                        '& span': {
                                            fontFamily: 'OnestRegular',
                                            color: '#202020',
                                            fontSize: '14px'
                                        },
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>

            <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    onClick={() => onClose()}
                    variant="text"
                    sx={{ 
                        marginRight: '10px',
                    }} >
                    {t('Cancel')}
                </Button>
                <Button
                    onClick={() => save()}
                    variant="contained">
                    {t('Save')}
                </Button>
            </Box>
        </>
    );
};

export default SourceOfFunds;