import { ADD_ADDRESS, CHANGE_DATA_ADDRESS, SET_ALL_DATA } from '../actions';
import { AnyAction } from 'redux'

const initialState = {
    data: {},
    checked: false
};

export default function addressesReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case SET_ALL_DATA:
            return { ...(action.payload?.addressesReducer || state) }
        case ADD_ADDRESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload
                }
            };
        case CHANGE_DATA_ADDRESS:
            return {
                ...state,
                [action.payload.id]: action.payload.data
            }
        default:
            return state;
    }
}