import React, { useState } from 'react';
import ActionButtons from "../../../infrastructure/section/action-buttons-section"
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import StepModal from './step-modal';
import FormSection from "../../../infrastructure/section/form-section"
import { setEducation } from '../../../services/actionCreators/mainActionCreator';

import './styles.scss';

const StepEducation = ({ userEmployment: { education }, handleNext, handleBack, isAdaptive }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const save = (educationLevel) => {
        dispatch(setEducation({ educationLevel: educationLevel || {} }))
    }

    return (
        <div className="step-container">
            <FormSection id="step-four" title={t('Step_5_title')}>
                <StepModal
                    {...{ education, save }}
                />
            </FormSection>
            <ActionButtons  
                {...{ handleNext, handleBack, isAdaptive }}
                backBtnLabel={t('Back_step')}
                nextBtnLabel={t('Next_step')}
            />
        </div>
    );
};

export default StepEducation;
