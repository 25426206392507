import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Router } from 'react-router-dom';
import Store from './services/store';
import { Provider } from 'react-redux';
import App from './App';
import { createBrowserHistory } from 'history';
import moment from "moment"
import { SnackbarProvider } from "notistack";
import "./i18n";
import './assets/fonts/onest/Onest-Regular.ttf';
import './assets/fonts/onest/Onest-Thin.ttf';
import './assets/fonts/onest/Onest-Black.ttf';
import './assets/fonts/onest/Onest-Bold.ttf';
import './assets/fonts/onest/Onest-ExtraBold.ttf';
import './assets/fonts/onest/Onest-Light.ttf';
import './assets/fonts/onest/Onest-Medium.ttf';

const history = createBrowserHistory();

const container = document.getElementById('root');
const root = createRoot(container);

moment.locale(Store.getState().prefsReducer.lng);

root.render(
    <Provider store={Store}>
        <SnackbarProvider autoHideDuration={2000}  >
            <Router history={history}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
            </Router>
        </SnackbarProvider>
    </Provider>
);
