import React, { useEffect, useState } from 'react';
import RadioComponent from './Radio';
import TextField from '@mui/material/TextField';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { citizenshipUSA, questions } from '../../../../../data/constants'
import { useTranslation } from 'react-i18next';

const IndicesModal = ({ onSave, data, onClose }) => {
    const [values, setValue] = useState({});
    const [citizenship, setCitizenship] = useState(citizenshipUSA[0].name);
    const [address, setAddresses] = useState('');
    const [postCode, setPostCode] = useState('');
    const [ssn, setSsn] = useState('');
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        const { values, citizenship, address, postCode, ssn } = data;

        values && setValue(values);
        citizenship && setCitizenship(citizenship);
        address && setAddresses(address);
        postCode && setPostCode(postCode)
        ssn && setSsn(postCode)
        setLoading(false);
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const v = { ...values };
        v[id] = event.target.value;
        setValue(v);
    };

    const save = () => {
        const data = { values, citizenship, address, postCode, ssn };
        onSave(data);
    };

 

    return (
        <>
            {!loading
                ? <RadioComponent
                    {...{ questions, values, handleChange, t }}
                />
                : (
                    <div className='circular-progress-block'>
                        <CircularProgress className="modal-progress" />
                    </div>
                )}

            <TextField
                className="input-address-modal input-country-input"
                label={t('Citizen_usa')}
                value={citizenship}
                onChange={e => setCitizenship(e.target.value)}
                onKeyDown={(event) => {
                    event.stopPropagation();
                }}
                variant="standard"
                select
            >
                <ListSubheader>{t('Citizen_usa')}</ListSubheader>
                {citizenshipUSA && citizenshipUSA.map(({ id, name }) => {
                    return (
                        <MenuItem key={id} value={name}>{t(name)}</MenuItem>
                    );
                })}
            </TextField>

            <TextField
                label={t('Address_usa')}
                variant="standard"
                value={address}
                onChange={e => setAddresses(e.target.value)}
                onKeyDown={(event) => {
                    event.stopPropagation();
                }}
            />
            <TextField
                label={t('Mail_usa')}
                variant="standard"
                value={postCode}
                onChange={e => setPostCode(e.target.value)}
                onKeyDown={(event) => {
                    event.stopPropagation();
                }}
            />
            <TextField
                label="SSN SUA"
                variant="standard"
                value={ssn}
                onChange={e => setSsn(e.target.value)}
                onKeyDown={(event) => {
                    event.stopPropagation();
                }}
            />

            <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                    onClick={() => onClose()}
                    variant="text"
                    sx={{ 
                        marginRight: '10px',
                    }} >
                    {t('Cancel')}
                </Button>
                <Button
                    onClick={() => save()}
                    variant="contained">
                    {t('Save')}
                </Button>
            </Box>
        </>
    );
};

export default IndicesModal;