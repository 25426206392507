import React from "react";
import FormSection from "../../../infrastructure/section/form-section";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CachedIcon from "@mui/icons-material/Cached";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { SuccessIcon } from "../../../infrastructure/components/helpers/success-icon";
import { ErrorIcon } from "../../../infrastructure/components/helpers/error-icon";

import "./styles.scss";

const SuccessStep = (props) => {
  const { handleReset, isErrorScreen } = props;

  const { t } = useTranslation();

  const renderErrorContainer = () => {
    return (
      <div className="case-error">
        <ErrorIcon />
        <h1 className="error-title"> {t("Sent_error")} </h1>
        <h4 className="error-subtitle"> {t("Send_to_a_check_error")} </h4>
        <Button
          onClick={() => window.location.reload()}
          variant="text"
          className="go-home"
        >
          {t("Sent_error_button")}
          <CachedIcon fontSize="small" sx={{ ml: 2 }} />
        </Button>
      </div>
    );
  };

  const renderSuccessContainer = () => {
    return (
      <div className="case-success">
        <SuccessIcon />
        <h1 className="success-title"> {t("Sent")} </h1>
        <h4 className="success-subtitle"> {t("Send_to_a_check")} </h4>
        <Button
          onClick={() => handleReset()}
          variant="text"
          className="go-home"
        >
          {t("Go_to_home_page")}
          <ArrowForwardIosIcon fontSize="small" sx={{ ml: 2 }} />
        </Button>
      </div>
    );
  };

  const content = isErrorScreen
    ? renderErrorContainer()
    : renderSuccessContainer();

  return (
    <FormSection id="step-success" contentCentered>
      <div className="success-container">{content}</div>
    </FormSection>
  );
};

export default SuccessStep;
