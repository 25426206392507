import {
    TEST_ACTION,
    ADD_MORE_PHONES,
    DELETE_PHONE_ITEM,
    EDIT_PHONE_ITEM,
    GET_USER_INFO,
    SET_IS_LOADING,
    ADD_ADDRESS,
    CHANGE_DATA_ADDRESS,
    ADD_DETAILS,
    DELETE_EMPLOYMENT_ITEM,
    SET_EDUCATION,
    EDIT_WORK_PLACE,
    SET_WORK_PLACE,
    ADD_MORE_WORK_PLACES,
    SET_ALL_DATA,
    CHANGE_LANGUAGE,
    CHANGE_PAGE,
    EDIT_USED_DATA,
    SET_ADRESS,
    SAVE_CAMERA_SCREENSHOT,
    SAVE_FATCA_VALUES,
    SET_FATCA_DETAILS,
    NO_DOUBLE_FETCH,
    SAVE_TOKEN
} from '../actions';
import axios from '../API';
// import { createServer } from 'miragejs';
// import userHandler from '../../mock-server/userHandler';
// import dictHandler from '../../mock-server/dictHandler';
import { IPhonesData } from '../../interfaces'
import { dictBaseUrl } from "../../data/constants"
import { loadState } from '../../infrastructure/components/helpers/localstorage';
const mockBaseUrl = '/api/user';

import i18n from "../../i18n";
import { storePrefLang } from "../Prefs";
import moment from "moment";

// createServer({
//     routes() {
//         userHandler(this, mockBaseUrl);
//         dictHandler(this, dictBaseUrl);
//         this.passthrough();
//     }
// });

export const setGlobalApiUrl = (apiUrl: string) => {
    return { type: TEST_ACTION, payload: apiUrl };
};

export const addMorePhones = () => {
    return { type: ADD_MORE_PHONES };
};

export const saveCameraScreenShot = (data: any) => {
    return { type: SAVE_CAMERA_SCREENSHOT, payload: data };
};

export const noDoubleFetch = () => {
    return { type: NO_DOUBLE_FETCH };
};

export const onDeletePhoneItem = (phoneIndex: number) => {
    return { type: DELETE_PHONE_ITEM, payload: phoneIndex };
};

export const onDeleteEmploymentItem = (employmentIndex: number) => {
    return { type: DELETE_EMPLOYMENT_ITEM, payload: employmentIndex };
};

export const setEducation = (data = {}) => {
    return { type: SET_EDUCATION, payload: data };
};

export const setWorkPlace = (data = {}) => {
    return { type: SET_WORK_PLACE, payload: data };
};

export const setFatcaDetails = (state, value) => {
    return { type: SET_FATCA_DETAILS, payload: { state, value } };
};

export const editWorkPlace = (state, value) => {
    return { type: EDIT_WORK_PLACE, payload: { state, value } };
};

export const addMoreWorkPlaces = () => {
    return { type: ADD_MORE_WORK_PLACES };
};

export const editPhoneItem = (phoneData: IPhonesData) => {
    return { type: EDIT_PHONE_ITEM, payload: phoneData };
};

export const editUsedData = (state, value) => {
    return { type: EDIT_USED_DATA, payload: { state, value } };
};

export const saveToken = (token) => {
    return { type: SAVE_TOKEN, payload: token };
};


export const setIsLoading = () => {
    return { type: SET_IS_LOADING };
};

// this mock data for get user info development when backend is down
// export const getUserInfo = () => {
//     return axios.get(`${mockBaseUrl}/getUserInfo`)
//         .then((res) => {

//             return { type: GET_USER_INFO, payload: res.data.response };
//         });
// };

export const setAddresses = (data) => {
    return { type: ADD_ADDRESS, payload: data };
};

export const setAddressDataState = (data) => {
    return { type: CHANGE_DATA_ADDRESS, payload: data }
}
export const setMainAdress = (data = {}) => {
    return { type: SET_ADRESS, payload: data }
}
export const saveFatcaValues = (values) => {
    return { type: SAVE_FATCA_VALUES, payload: values };
}
export const setDetails = (data) => {
    return { type: ADD_DETAILS, payload: data };
};

export const setAllData = () => {
    return { type: SET_ALL_DATA, payload: loadState() };
};

export const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    moment.locale(lng);
    storePrefLang(lng);
    return { type: CHANGE_LANGUAGE, payload: lng };
}
export const changePage = (page: number) => {
    return { type: CHANGE_PAGE, payload: page };
};