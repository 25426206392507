import React, { useState } from "react";
// import FormSection from "../../section/form-section"
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import { getCurrentLanguage } from "../../../services/selectors/languageSelector";
import { changeLanguage } from "../../../services/actionCreators/mainActionCreator";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { LogoGeneric } from "../helpers/logo-generic";
import StepperComponent from "../../../pages/stepper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./styles.scss";
import { t } from "i18next";

const Header = ({ activeStep, isAdaptive }) => {
  const language = useSelector(getCurrentLanguage);

  const [currentLanguage, setcurrentLanguage] = useState(language);

  const handleLanguageChange = (lang) => {
    setcurrentLanguage(lang);
    changeLanguage(lang);
  };

  return (
    <div className="header">
      {/* <div className="languages-container">
                <div className={`${currentLanguage === "ro" && "active-lang"}`} onClick={() => handleLanguageChange("ro")}>RO</div>
                <div className={`${currentLanguage === "ru" && "active-lang"}`} onClick={() => handleLanguageChange("ru")}>RU</div>
                <div className={`${currentLanguage === "en" && "active-lang"}`} onClick={() => handleLanguageChange("en")}>EN</div>
            </div> */}
      <FormControl className="select-lang-container">
        {/* <InputLabel id="demo-simple-select-label">{t('Employment')}</InputLabel> */}
        <Select
          className="select-lang-selector"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={currentLanguage}
          onChange={(e) => handleLanguageChange(e.target.value)}
          IconComponent={(props) => (
            <i {...props} className={`material-icons ${props.className}`}>
              <ExpandMoreIcon />
            </i>
          )}
        >
          <MenuItem value={"ro"} key={0}>
            RO
          </MenuItem>
          <MenuItem value={"ru"} key={1}>
            RU
          </MenuItem>
          <MenuItem value={"en"} key={2}>
            EN
          </MenuItem>
        </Select>
      </FormControl>

      <div className="header-info">
        {/* <Logo /> */}
        <LogoGeneric />
        <div>
          <Typography variant="h4" className="header-title">
            {t("Header_title")}
          </Typography>
          <Typography variant="h6" className="header-description">
            {t("Header_sub_title")}
          </Typography>
        </div>
      </div>

      {isAdaptive && (
        <StepperComponent {...{ activeStep }} orientation={"horizontal"} />
      )}
    </div>
  );
};

export default Header;
