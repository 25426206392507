import React from 'react';
import { ICheckInfoContainerProps } from "./types"
import { EditIcon } from '../../../infrastructure/components/helpers/edit-icon';
import { useDispatch } from 'react-redux';
import { noDoubleFetch } from '../../../services/actionCreators/mainActionCreator'

import './styles.scss';

function CheckInfoContainer({ children, step, title, goPage, isFetch = false }: ICheckInfoContainerProps) {
    const dispatch = useDispatch();
    const setInRedux = () => {
        dispatch(noDoubleFetch())
        goPage(step)
    }
    return (
        <div className="check-info-container">
            <div className="check-info-container-header">
                <div className="check-info-title">{title}</div>
                {step && <EditIcon onClick={() => isFetch ? setInRedux() : goPage(step)} />}
            </div>
            <div className="check-info-container-body">
                {children}
            </div>
        </div>
    );
};

export default CheckInfoContainer;
