export const formatAddress = (data) => {
    const { locality, region, street, house, block, flat } = data;
    return `${locality} ${region} ${street} ${house} ${block} ${flat}`
}

export const noZeroFirstDecimalNumber = (value) => {
    //ex. 1234567.99   //no zero in first number
    const reg = /^$|^[1-9]\d*$|^\.\d+$|^0\.\d*$|^[1-9]\d*\.\d*$/;
    return reg.test(value)
}

export const checkIDNPNumberValidation = (idnp) => {
    const number = idnp;
    const stringNumber = number.toString();

    let sumIndex = 0;
    let sumIndex2 = 0;
    let sumIndex3 = 0;

    // 123456789A B C D
    // 2005001021 8 6 5 
    // 0123456789 101112
    // D=((1+4+7+A)*7+(2+5+8+B)*3+(3+6+9+C)) MOD 10

    for (let i = 0; i < stringNumber.length; i++) {
        const digit = parseInt(stringNumber[i]);
        if (i === 0 || i === 3 || i === 6 || i === 9) {
            sumIndex += digit;
        }
        if (i === 1 || i === 4 || i === 7 || i === 10) {
            sumIndex2 += digit;
        }
        if (i === 2 || i === 5 || i === 8 || i === 11) {
            sumIndex3 += digit;
        }
    }

    const result = (sumIndex * 7) + (sumIndex2 * 3) + sumIndex3;

    const remainder = result % 10;

    const lastIndexIDNP = idnp.charAt(idnp.length - 1);
    
    const  checkIDNPNumberValidationResult = lastIndexIDNP === remainder.toString() ? true : false

    return checkIDNPNumberValidationResult;
}

export const ADAPTIVE_WIDTH = 1200