import {
    TEST_ACTION,
    ADD_MORE_PHONES,
    DELETE_PHONE_ITEM,
    EDIT_PHONE_ITEM,
    GET_USER_INFO,
    SET_IS_LOADING,
    DELETE_EMPLOYMENT_ITEM,
    SET_EDUCATION,
    EDIT_WORK_PLACE,
    SAVE_TOKEN,
    SET_ALL_DATA,
    CHANGE_PAGE,
    EDIT_USED_DATA,
    SET_ADRESS,
    SAVE_CAMERA_SCREENSHOT,
    SET_WORK_PLACE,
    NO_DOUBLE_FETCH
} from '../actions';
import { emptyPhoneInfo, emptyWorkPlace, defaultInitialState } from '../../data/constants';
import { AnyAction } from 'redux'

const initialState = { ...defaultInitialState };

export default function mainReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case SET_ALL_DATA: {
            return { ...(action.payload?.mainReducer || state) }
        }
        case TEST_ACTION: {
            return { ...state, testObj: action.payload };
        }
        case ADD_MORE_PHONES: {
            return {
                ...state,
                userData: {
                    ...state.userData,
                    phones: [
                        ...state.userData.phones,
                        { ...emptyPhoneInfo }
                    ]
                }
            };
        }
        case SAVE_CAMERA_SCREENSHOT:
            return {
                ...state,
                cameraScreenShot: action.payload
            };

        case NO_DOUBLE_FETCH:
            return {
                ...state,
                doubleFetch: false
            };
        case SAVE_TOKEN:
            return {
                ...state,
                token: action.payload
            };

        case DELETE_PHONE_ITEM: {
            const phones = state.userData.phones;
            const phoneIndex = action.payload;

            const newArray = [
                ...phones.slice(0, phoneIndex),
                ...phones.slice(phoneIndex + 1)
            ];

            return {
                ...state,
                userData: {
                    ...state.userData,
                    phones: [...newArray]
                }
            };
        }

        case EDIT_PHONE_ITEM: {
            const phones = state.userData.phones;
            const idx = phones.findIndex((el, index) => index === action.payload.phoneIndex);

            const newArray = [
                ...phones.slice(0, idx),
                action.payload,
                ...phones.slice(idx + 1)
            ];
            return {
                ...state,
                userData: {
                    ...state.userData,
                    phones: [...newArray]
                }
            };
        }

        case EDIT_USED_DATA: {
            const { state: customState, value } = action.payload
            return {
                ...state,
                userData: {
                    ...state.userData,
                    [customState]: value
                }
            };
        }


        case GET_USER_INFO: {
            return {
                ...state,
                userData: { ...action.payload },
                isLoading: false
            };
        }

        case SET_IS_LOADING: {
            return {
                ...state,
                isLoading: !state.isLoading
            };
        }
        case SET_ADRESS: {
            return {
                ...state,
                adress: { ...action.payload }
            };
        }


        case SET_EDUCATION: {
            return {
                ...state,
                employment: {
                    ...state.employment,
                    education: action.payload.educationLevel,
                }
            };
        }

        // case ADD_MORE_WORK_PLACES: {
        //     return {
        //         ...state,
        //         employment: {
        //             ...state.employment,
        //             workPlaces: [
        //                 ...state.employment.workPlaces,
        //                 { ...emptyWorkPlace }
        //             ]
        //         }
        //     };
        // }


        case DELETE_EMPLOYMENT_ITEM: {
            const workPlaces = state.employment.workPlaces;
            const phoneIndex = action.payload;

            const newArray = [
                ...workPlaces.slice(0, phoneIndex),
                ...workPlaces.slice(phoneIndex + 1)
            ];

            return {
                ...state,
                employment: {
                    ...state.employment,
                    workPlaces: [...newArray]
                }
            };
        }

        case SET_WORK_PLACE: {
            return {
                ...state,
                employment: {
                    ...state.employment,
                    workPlaces: {
                        ...action.payload
                    }
                }
            };
        }

        case EDIT_WORK_PLACE: {
            // const workPlaces = state.employment.workPlaces;
            // const idx = workPlaces.findIndex((el, index) => index === action.payload.workPlaceIndex);
            // const newArray = [
            //     ...workPlaces.slice(0, idx),
            //     action.payload,
            //     ...workPlaces.slice(idx + 1)
            // ];

            return {
                ...state,
                employment: {
                    ...state.employment,
                    workPlaces: {
                        ...state.employment.workPlaces,
                        [action.payload.state]: action.payload.value
                    }
                }
            };
        }

        case CHANGE_PAGE: {
            return {
                ...state,
                page: action.payload
            }
        }

        default:
            return state;
    }
}
