import {
    SAVE_INCOMMING_DATA
} from '../actions';

import { AnyAction } from 'redux'

const initialState = { 
    incommingData: {}
 };

export default function mainReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case SAVE_INCOMMING_DATA: {
            return {
                ...state,
                incommingData: action.payload
            }
        }

        default:
            return state;
    }
}
