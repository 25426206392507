export const TEST_ACTION = 'TEST_ACTION';
export const DELETE_PHONE_ITEM = 'DELETE_PHONE_ITEM';
export const ADD_MORE_PHONES = 'ADD_MORE_PHONES';
export const EDIT_PHONE_ITEM = 'EDIT_PHONE_ITEM';
export const GET_USER_INFO = 'GET_USER_INFO';
export const SET_IS_LOADING = 'SET_IS_LOADING';

export const ADD_ADDRESS = 'ADD_ADDRESS';
export const CHANGE_DATA_ADDRESS = 'CHANGE_DATA_ADDRESS';
export const ADD_DETAILS = 'ADD_DETAILS';
export const DELETE_EMPLOYMENT_ITEM = 'DELETE_EMPLOYMENT_ITEM';
export const SET_EDUCATION = 'SET_EDUCATION';
export const EDIT_WORK_PLACE = 'EDIT_WORK_PLACE';
export const SET_WORK_PLACE = 'SET_WORK_PLACE'; 
export const ADD_MORE_WORK_PLACES = 'ADD_MORE_WORK_PLACES';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'; 
export const SET_ALL_DATA = 'SET_ALL_DATA'
export const CHANGE_PAGE = 'CHANGE_PAGE'
export const EDIT_USED_DATA = 'EDIT_USED_DATA'
export const SET_ADRESS = 'SET_ADRESS' 
export const NO_DOUBLE_FETCH = 'NO_DOUBLE_FETCH'  
export const SAVE_CAMERA_SCREENSHOT = 'SAVE_CAMERA_SCREENSHOT'
export const SAVE_FATCA_VALUES = "SAVE_FATCA_VALUES";
export const SET_FATCA_DETAILS = "SET_FATCA_DETAILS"; 
export const SAVE_INCOMMING_DATA = "SAVE_INCOMMING_DATA"; 
// token related
export const FETCH_TOKEN = "FETCH_TOKEN";
export const FETCH_TOKEN_SUCCESS = "FETCH_TOKEN_SUCCESS";
export const FETCH_TOKEN_FAIL = "FETCH_TOKEN_FAIL";
export const REMOVE_TOKEN = "REMOVE_TOKEN";
export const CHANGE_LOGIN = "CHANGE_PHONE";
export const TOGGLE_REMEMBER_SESSION = "TOGGLE_REMEMBER_SESSION";
export const CHANGE_PASSWORD = "CHANGE_CODE";
export const BASE_URL = "BASE_URL"; 
export const SAVE_TOKEN = "SAVE_TOKEN"

/* DICTIONARIES */
export const GET_DICT_COUNTRIES = "GET_DICT_COUNTRIES"