import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import CompletedModal from './components/CompletedModal';
import IndicesModal from './components/IndicesModal';
import TrafficModal from './components/TrafficModal'
import SourceOfFundsModal from './components/SourceOfFundsModal';
import DealCostModal from './components/DealCostModal';
import { setDetails } from '../../../../services/actionCreators/mainActionCreator';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
interface IProps {
    onClose: () => void
    opened: boolean
    title: string
    type: number
    // isFatca: boolean
    data: IDetailsReducerData
}

const OperationModal = ({ onClose, opened, type, data }: IProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const onSave = (data) => {
        dispatch(setDetails({ ...data }))
        enqueueSnackbar(t('Saved'))
        onClose();
    }

    const getTitle = () => {
        // if (isFatca) return 'FATCA_details'

        switch(type) {
            case 1: return 'Performed_operations'
            case 2: return 'Target_purpose'
            case 3: return 'Average_monthly_traffic'
            case 4: return 'Average_trade_value'
            case 5: return 'Source_of_funds'
        }
    }

    return (
        <Modal
            open={opened}
            onClose={() => onClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus={true}
            className="modal-step-six"
        >
            <Box className="modal-content-step-six">

                <div className="modal-header-wrapper">
                    <Typography id="modal-modal-title" variant="h6" component="h2" 
                        sx={{
                            fontFamily: 'OnestBold',
                            color: '#1D2126',
                            fontSize: '18px',
                            lineHeight: '25.2px'
                        }}
                    >
                        {t(getTitle())}
                    </Typography>
                    <CloseIcon onClick={() => onClose()} sx={{color:'#606060', cursor: 'pointer'}} />
                </div>

                {type === 1 && <CompletedModal {...{ data, type, onSave, onClose }} />}
                {type === 2 && <CompletedModal {...{ data, type, onSave, onClose }} />}
                {type === 3 && <TrafficModal {...{ data, onSave, onClose }} />}
                {type === 4 && <DealCostModal {...{ data, onSave, onClose }} />}
                {type === 5 && <SourceOfFundsModal {...{ data, onSave, onClose }} />}
                {type === 6 && <IndicesModal {...{ data, onSave, onClose }} />}

            </Box>
        </Modal>
    )
}

export default OperationModal;