import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import axios from "../../../services/API";
import {
  saveToken,
  setAllData,
} from "../../../services/actionCreators/mainActionCreator";
import {
  otpInputs,
  onlyNumbersRegex,
  TIMER_RESEND_OTP,
  CONFIRM_SMS,
  RESEND_SMS,
} from "../../../data/constants";
import { IStepOtp } from "../../../interfaces";
import { useTranslation } from "react-i18next";
import FormSection from "../../../infrastructure/section/form-section";
import CircularProgress from "@mui/material/CircularProgress";
import { getBaseUrl } from "../../../services/selectors/baseUrlSelector";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import { getToken } from "../../../services/selectors/mainSelector";
import { enqueueSnackbar } from "notistack";
import OtpInput from "react-otp-input";
import "./styles.scss";

const StepOtp = (props: IStepOtp) => {
  const {
    userData: { phoneNumber },
    handleNext,
    handleBack,
    isAdaptive,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const baseUrl = useSelector(getBaseUrl);
  const token = useSelector(getToken);

  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState("");
  const [showHelpOTPButton, setShowHelpOTPButton] = useState(false);
  const [digitsError, setDigitsError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [resendMessage, setResendMessage] = useState(false);
  const [showResendButton, setShowResendButton] = useState(false);
  const [newOtp, setNewOtp] = useState("");

  const [currentCount, setCount] = useState(TIMER_RESEND_OTP);
  const timer = () => setCount(currentCount - 1);

  useEffect(() => {
    if (currentCount <= 0) {
      setShowResendButton(true);
      return;
    }
    const id = setInterval(timer, 1000);
    return () => clearInterval(id);
  }, [currentCount]);

  useEffect(() => {
    const onResendMessage = () => {
      setNewOtp("");
      setCount(TIMER_RESEND_OTP);
      setShowResendButton(false);
      setResendMessage(false);
      setError("");

      axios.get(`${baseUrl}/login/resend/sms/${token}`).then((res) => {
        if (res.status === 200 || res.status === 201) {
          const resIsOk = res.data.result === RESEND_SMS.OK;
          if (res.data.result === RESEND_SMS.NO_ATTEMPTS_LEFT) {
            enqueueSnackbar(t("No_attempts_left"));
            handleBack();
          }
          !resIsOk && checkResponse(res.data.result);
          if (resIsOk) {
            enqueueSnackbar(t("Sent"));
          }
        } else {
          setError("OTP_Error_message");
          setNewOtp("");
          // setTimeout(() => {
          //     document.getElementById(`otp-input-0`).focus();
          // }, 1000);
        }
      });
    };

    resendMessage && onResendMessage();
  }, [resendMessage]);

  // useEffect(() => {
  //     document.getElementById(`otp-input-0`).focus();
  // }, [])

  const checkResponse = (response) => {
    let text;
    let helpOTPButton;

    switch (response) {
      case CONFIRM_SMS.INVALID_OTP:
        text = "Invalid_otp";
        break;
      case CONFIRM_SMS.NO_ATTEMPTS_LEFT:
        text = "No_attempts_left";
        helpOTPButton = true;
        break;
      case CONFIRM_SMS.OTP_EXPIRED:
        text = "Otp_expired";
        break;
      case CONFIRM_SMS.OTP_NOT_FOUND:
        text = "Otp_not_found";
        helpOTPButton = true;
        break;
      case RESEND_SMS.TO_EARLY:
        text = "Too_early";
        break;

      default:
        break;
    }

    setError(text);
    setShowHelpOTPButton(helpOTPButton);
  };

  const recoverData = () => {
    dispatch(setAllData());
  };

  const checkOtp = (editedOtp) => {
    setIsFetching(true);
    setError("");
    setSuccess(false);

    //  const correctOtp = `${editedOtp[0]}${editedOtp[1]}${editedOtp[2]}${editedOtp[3]}${editedOtp[4]}${editedOtp[5]}`

    axios
      .post(`${baseUrl}/login/confirm/sms/${token}?otp=${editedOtp}`)
      .then((res) => {
        // console.log("res / checkOtp ->> ", res)
        if (res.status === 200 || res.status === 201) {
          const resIsVerified = res.data.result === CONFIRM_SMS.VERIFIED;
          !resIsVerified && checkResponse(res.data.result);

          if (resIsVerified) {
            const newToken = res.headers.authtoken || "";
            dispatch(saveToken(newToken));
            setSuccess(true);
            handleNext();
          }
        } else {
          setError("OTP_Error_message");
          setNewOtp("");
          // setTimeout(() => {
          //     document.getElementById(`otp-input-0`).focus();
          // }, 1000);
        }

        setIsFetching(false);
      })
      .catch((err) => {
        setIsFetching(false);
      });
  };

  const otpCheckInProcess = () => {
    return (
      <div className="otp-process">
        {/* <h3>{t('Check_entered_otp')}</h3> */}
        <CircularProgress size={18} className="circular-yellow" />
      </div>
    );
  };

  const otpCheckSuccess = () => {
    return (
      <div className="otp-success">
        <DoneIcon color="success" />
      </div>
    );
  };

  const otpCheckFailed = () => {
    return (
      <div className="otp-failed-container">
        {/* <label>{t('OTP_Error_message')}</label> */}
        <div className="otp-failed">
          <ErrorIcon color="error" />
          <label>{t(error)}</label>
        </div>
        {showHelpOTPButton && (
          <div onClick={recoverData} className="modal-prev-btn">
            {t("Try_now")}
          </div>
        )}
      </div>
    );
  };

  const digitsOtpError = () => {
    return (
      <div className="otp-failed">
        <ErrorIcon color="error" />
        <label>{t("Enter_only_numbers")}</label>
      </div>
    );
  };

  const setOtp = (value) => {
    setError("");
    setDigitsError(false);

    const isValid = onlyNumbersRegex(value);
    if (isValid || value === "") {
      // const checkedOtp = {
      //     ...newOtp,
      //     [index]: value
      // }
      setNewOtp(value);

      if (value.length === 6) {
        checkOtp(value);
      }
    } else {
      setDigitsError(true);
    }
  };

  const renderSubTitle = () => {
    const text = `${t("OTP_Message_info_one")} <b>${phoneNumber}</b> ${t(
      "OTP_Message_info_part_two"
    )}`;
    return <div dangerouslySetInnerHTML={{ __html: text }} />;
  };

  const subTitle = renderSubTitle();

  // const renderOtpInputs = () => {

  //     const content = otpInputs.map((i, index) => {
  //         return (
  //             <TextField
  //                 className="text-input-otp"
  //                 id={`otp-input-${index}`}
  //                 key={index}
  //                 onChange={(e) => setOtp(e.target.value, index)}
  //                 variant="outlined"
  //                 placeholder="0"
  //                 disabled={index === 0 ? false : !newOtp[index - 1]}
  //                 value={newOtp[index] || ""}
  //                 type={'number'}
  //                 inputMode={'numeric'}
  //                 inputProps={{
  //                     maxLength: 1,
  //                     pattern: "[0-9]*"
  //                 }}
  //             />
  //         )
  //     })

  //     return content
  // }
  const renderOtpInputs = () => {
    return (
      // <TextField
      //     className="text-input-otp"
      //     id={`otp-input-${index}`}
      //     key={index}
      //     onChange={(e) => setOtp(e.target.value, index)}
      //     variant="outlined"
      //     placeholder="0"
      //     disabled={index === 0 ? false : !newOtp[index - 1]}
      //     value={newOtp[index] || ""}
      //     type={'number'}
      //     inputMode={'numeric'}
      //     inputProps={{
      //         maxLength: 1,
      //         pattern: "[0-9]*"
      //     }}
      // />
      <OtpInput
        value={newOtp}
        onChange={(e) => setOtp(e)}
        numInputs={6}
        shouldAutoFocus
        inputStyle="text-input-otp"
        renderInput={(props, i) => {
          return (
            <input
              {...props}
              type={"number"}
              inputMode={"numeric"}
              // disabled={ newOtp.length < i}
            />
          );
        }}
      />
    );

    //return content;
  };

  const renderFormSection = () => {
    return (
      <FormSection
        id="step-otp"
        title={t("Phone_number_check")}
        contentCentered={!isAdaptive}
        noFullHeight
        hideTitleAndSubtitle={isFetching}
        {...{ subTitle }}
      >
        <div className="user-data-container">
          <div className="step-one-substeps-container">
            {isFetching ? (
              otpCheckInProcess()
            ) : (
              <div className="otp-container">
                <div className="justify-align-center">{renderOtpInputs()}</div>
                <div className="resend-container">
                  <div>{t("No_code")}</div>
                  {showResendButton ? (
                    <span onClick={() => setResendMessage(true)}>
                      {t("Send_sms_one_more_time")}
                    </span>
                  ) : (
                    <b>
                      {t("Can_send_after")} {currentCount} {t("Sec")}
                    </b>
                  )}
                </div>
              </div>
            )}
            {success && otpCheckSuccess()}
            {error && otpCheckFailed()}
            {digitsError && digitsOtpError()}
          </div>
        </div>
      </FormSection>
    );
  };

  return <div className="step-container">{renderFormSection()}</div>;
};

export default StepOtp;
