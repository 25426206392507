import { RootGetState } from '../../../services/store'
const storeName = "store"

const versionName = "version"
const version = "1.2"

export const saveDataLS = (data: RootGetState) => {
    localStorage.setItem(storeName, JSON.stringify(data))
    localStorage.setItem(versionName, version)
}

export const loadState = () => {
    const data = localStorage.getItem(storeName)
    const versionLS = localStorage.getItem(versionName)

    if (data) {
        if (versionLS === version) return JSON.parse(data)
        else deleteDataLS()
    }
}

export const deleteDataLS = () => {
    localStorage.removeItem(storeName)
    localStorage.removeItem(versionName)
}