import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { getDetailsState } from '../../../services/selectors/detailsSelector';
import { performedOperations, incomeInterval, sourcesOfFunds, transactionDeals } from '../../../data/constants'
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import OperationModal from './operation-modal/OperationModal';
import FormSection from "../../../infrastructure/section/form-section"
import ActionButtons from "../../../infrastructure/section/action-buttons-section"
import TooltipWrapper from "../../../infrastructure/components/tooltip-wrapper"
import './styles.scss'

const StepIncome = ({ handleNext, handleBack, isAdaptive }) => {
    const { t } = useTranslation();
    const [employeeModal, showEmployeeModal] = useState(false);
    const dataModal = [
        {
            id: 1,
            title: t('Performed_operations'),
            subTitle: t('Card_transactions'),
            reduxState: "operations",
            checkedInObj: true,
            arrToMap: performedOperations,
            tooltipText: "Income_text_1"
        },
        {
            id: 2,
            title: t('Target_purpose'),
            subTitle: t('Financial_operations'),
            reduxState: "target",
            checkedInObj: true,
            arrToMap: performedOperations,
            tooltipText: "Income_text_2"
        },
        {
            id: 3,
            title: t('Average_monthly_traffic'),
            subTitle: t('Up_to_500'),
            reduxState: "radiosTraffic",
            checkedInObj: false,
            arrToMap: [],
            tooltipText: "Income_text_3"
        },
        {
            id: 4,
            title: t('Average_trade_value'),
            subTitle: t('Up_to_500'),
            reduxState: "dealCost",
            checkedInObj: false,
            arrToMap: [],
            tooltipText: "Income_text_4"
        },
        {
            id: 5,
            title: t('Source_of_funds'),
            subTitle: t('Wage'),
            reduxState: "checkedFunds",
            checkedInObj: false,
            arrToMap: sourcesOfFunds,
            tooltipText: "Income_text_5"
        }
    ];


    const [selectedDataId, setSelectedDataId] = useState(null);
    const [opened, setOpened] = useState(false);

    const data = useSelector(getDetailsState) as IDetailsReducerData;

    const selectOperation = (id) => {

        setOpened(true);
        setSelectedDataId(id);
    };

    const renderCheckedInfoBlock = (targetArr, arr) => {
        const content = arr.map((i, index) => {
            const title = targetArr[i - 1].name
            return (
                <div className="checked-option-wrapper" key={index}><div className="checked-option-dot"/>{t(title)}; &nbsp;</div>
            )
        });
        return content
    }

    let disabled = true

    return (
        <div className="step-container">

            <OperationModal
                onClose={() => setOpened(false)}
                type={selectedDataId}
                title={t('Performed_operations')}
                data={data as IDetailsReducerData}
                {...{ opened }}
            />

            <FormSection id="step-six" title={t('Step_7_title')}>
                <div className="page-six-container">
                    {dataModal && dataModal.map(({ id, title, checkedInObj, reduxState, arrToMap, tooltipText }, index) => {
                        const updatedArr = checkedInObj ? data[reduxState].checked : data[reduxState]
                        const other = data[reduxState]?.other || ""
                        const isOperations = reduxState === "operations"
                        const isTarget = reduxState === "target"
                        const isRadiosTraffic = reduxState === "radiosTraffic"
                        const isCheckedFundsItem = reduxState === "checkedFunds"
                        const isDealCost = reduxState === "dealCost"
                        const traffic = isRadiosTraffic ? incomeInterval.find(({ id }) => id === Number(data[reduxState]))?.name : ""
                        const dealCost = isDealCost ? transactionDeals.find(({ id }) => id === Number(data[reduxState]))?.name : ""
                        if ((other.length > 0 || updatedArr.length > 0)) disabled = false

                        return (
                            <div className="details-container" key={index}>
                                <div className="paper-details" key={id} onClick={() => selectOperation(id)}>
                                    <div className="details-info-block">
                                        <div className="details-info-label-wrapper">
                                            <Typography className="operation-title">
                                                {title}
                                            </Typography>
                                            <TooltipWrapper
                                                text={t(tooltipText)}
                                                tooltipTitle={title}
                                            >
                                                <IconButton className="info-btn">
                                                    <span className="material-symbols-outlined">
                                                        info_i
                                                    </span>
                                                </IconButton>
                                            </TooltipWrapper>
                                        </div>
                                        <div className="operation-type-wrapper">
                                            <div className="operation-type">
                                                {isOperations && renderCheckedInfoBlock(arrToMap, updatedArr)}
                                                {isTarget && renderCheckedInfoBlock(arrToMap, updatedArr)}
                                                {isCheckedFundsItem && renderCheckedInfoBlock(arrToMap, updatedArr)}
                                            </div>
                                            <div className="operation-type">
                                                {isRadiosTraffic && <div className="operation-type-doted">{t(traffic)}</div> || isDealCost && <div className="operation-type-doted">{t(dealCost)}</div>}
                                            </div>
                                        </div>
                                        
                                        {other && <Typography className="operation-type-other">
                                            {other}
                                        </Typography>}
                                    </div>
                                    <div className="arr-container">
                                        <ArrowForwardIosIcon fontSize="small" className="details-arrow" />
                                    </div>

                                </div>
                            </div>

                        );
                    })}
                </div>
            </FormSection>
            <ActionButtons
                {...{
                    disabled,
                    handleNext,
                    handleBack,
                    isAdaptive
                }}
                backBtnLabel={t('Back_step')}
                nextBtnLabel={t('Next_step')}
            />
        </div>
    )
}

export default StepIncome