import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import TextField from '@mui/material/TextField';
import axios from "../../../services/API";
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { editUsedData, saveToken } from '../../../services/actionCreators/mainActionCreator';
import { IDNP_MAX_LENGHT, PHONE_MAX_LENGHT } from '../../../data/constants';
import { IStepOne } from '../../../interfaces';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import CircularProgress from '@mui/material/CircularProgress';
import FormSection from "../../../infrastructure/section/form-section"
import { IconButton, Tooltip } from '@mui/material';
import ActionButtons from "../../../infrastructure/section/action-buttons-section"
import { getBaseUrl } from '../../../services/selectors/baseUrlSelector'
import ErrorIcon from '@mui/icons-material/Error';
import { checkIDNPNumberValidation } from '../../../infrastructure/components/helpers/utils';
import TooltipWrapper from "../../../infrastructure/components/tooltip-wrapper"
import Link from '@mui/material/Link';

// import { createServer } from 'miragejs';
// import firstStepHandler from '../../../mock-server/firstStepHandler';

// createServer({
//     routes() {
//         firstStepHandler(this, process.env.PUBLIC_URL);
//         this.passthrough();
//     }
// });

function StepAuth(props: IStepOne) {
    const {
        userData:
        {
            idnp,
            agreeWithRules,
            phoneNumber,
        },
        handleNext,
        isAdaptive
    } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const baseUrl = useSelector(getBaseUrl)

    const [isFetching, setIsFetching] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const [canMoveForward, setCanMoveForward] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState("")

    const onChangeUsedData = (state, value) => {
        //|| state === "otp"
        if (state === "idnp" || state === "phoneNumber") {
            dispatch(editUsedData(state, value));
        } else {
            dispatch(editUsedData(state, value));
        }
    };

    const checkInfo = () => {
        setIsDisabled(true)
        setIsFetching(true)

        // const params = {
        //     // idnp: mockIdnp,
        //     phone: phoneNumber,
        //     // phone: "+37369496713"
        // };

        const phone = phoneNumber.replace(/\-/g, "").replace(/\(/g, "").replace(/\)/g, "").replace(/\ /g, "").replace(/\+/g, "%2B")

        // setTimeout(() => {
        // ${idnp}
        axios.post(`${baseUrl}/login/init/${idnp}?phone=${phone}`)
            // axios.post(`${baseUrl}/login/init/`)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    setCanMoveForward(true)

                    const token = res.headers.authsession || ""
                    dispatch(saveToken(token))
                    handleNext()
                } else {
                    setError(true)
                }
                setIsDisabled(false)
                setIsFetching(false)

            })
            .catch(err => {
                setIsFetching(false)
            });
        // }, 1500);

    }

    const isCheckPointNumberOfIDNP = idnp.length === IDNP_MAX_LENGHT ? checkIDNPNumberValidation(idnp) : '';

    const enterIdnpContainer = () => {
        return (
            <>
                <div className="get-idnp-container">
                    <div className="justify-align-center mb-20">
                        <InputMask
                            mask="(+373) 99-999-999"
                            id="phoneNumberFiled"
                            value={phoneNumber}
                            onChange={(e) => onChangeUsedData("phoneNumber", e.target.value)}
                        >
                            {() =>
                                <TextField
                                    className="text-input"
                                    placeholder={t('Mobile_phone_number_label')}
                                    id="outlined-basic"
                                    variant="outlined"
                                    label={t('Mobile_phone_number_label')}
                                    inputProps={{
                                        tabIndex: 1
                                    }}
                                />
                            }
                        </InputMask>

                        <TooltipWrapper
                            text={t('Mobile_phone_number_info')}
                            mt0
                        >
                            <IconButton className="info-btn">
                                <span className="material-symbols-outlined">
                                    help
                                </span>
                            </IconButton>
                        </TooltipWrapper>
                    </div>

                    <div className="justify-align-center mb-20">
                        <InputMask
                            mask="9999999999999"
                            value={idnp}
                            onChange={(e) => onChangeUsedData("idnp", e.target.value)}
                            maskChar={""}
                        >
                            {() =>
                                <TextField
                                    className="text-input"
                                    fullWidth
                                    id="outlined-basic"
                                    label="IDNP"
                                    variant="outlined"
                                    inputProps={{
                                        tabIndex: 2
                                    }}
                                />
                            }
                        </InputMask>

                        <TooltipWrapper
                            text={t('IDNP_info')}
                            mt0
                        >
                            <IconButton className="info-btn">
                                <span className="material-symbols-outlined">
                                    help
                                </span>
                            </IconButton>
                        </TooltipWrapper>
                    </div>
                    {isCheckPointNumberOfIDNP === false &&
                        <div className="otp-failed">
                            <ErrorIcon color="error" />
                            <label>{t('Invalid_IDN')}</label>
                        </div>
                    }

                </div>

                {error && <div className="error">{t('OTP_Data_error_message')}</div>}
                {success && <div className="success">{success}</div>}
                <FormGroup className="form-checkbox" tabIndex={3}>
                    <FormControlLabel
                        control={
                            <Checkbox checked={agreeWithRules}
                                onChange={(e) => onChangeUsedData("agreeWithRules", e.target.checked)} />
                        }
                        label={
                            <div className="agree-rules-wrapper">
                                <div
                                    className="form-text-checkbox"
                                    dangerouslySetInnerHTML={{ __html: t('Agree_to_rules') }}
                                />
                            </div>
                        }
                    />
                </FormGroup>
            </>
        )
    }

    const intFormatPhoneMaskValue = phoneNumber.replace(/\D/g, "").length !== PHONE_MAX_LENGHT

    const disabled = !idnp || !isCheckPointNumberOfIDNP || intFormatPhoneMaskValue || !agreeWithRules || idnp.length !== IDNP_MAX_LENGHT || isDisabled

    return (
        <div className="step-container">
            <FormSection
                id="step-one"
                title={t('Auth_person')}
                subTitle={!isFetching && t('Enter_your_data')}
                contentCentered={!isAdaptive}
            >
                <div className="user-data-container">
                    <div className="step-one-substeps-container">
                        {isFetching ? <CircularProgress size={24} className="is-loading" /> : enterIdnpContainer()}
                    </div>
                </div>
            </FormSection>
            <ActionButtons
                handleNext={() => canMoveForward ? handleNext() : checkInfo()}
                nextBtnLabel={t('Next_step')}
                hideBackButton
                {...{ disabled, isAdaptive }}
            />
        </div>
    );
};

export default StepAuth;