import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { getUserDataState, getEmployment, getAdressState } from '../../../services/selectors/mainSelector';
import { getDataState, getCheckedState } from '../../../services/selectors/addressesSelector';
import { getDetailsState } from '../../../services/selectors/detailsSelector';
import { getAllIncommingState } from '../../../services/selectors/incommingDataSelector';
import { formatAddress } from '../../../infrastructure/components/helpers/utils'
import { performedOperations, incomeInterval, transactionDeals, sourcesOfFunds, questions, YES, NO, educationList } from '../../../data/constants'
import { changePage } from '../../../services/actionCreators/mainActionCreator'
import { enqueueSnackbar } from 'notistack';
import moment from "moment";
import Modal from '@mui/material/Modal';
import FormSection from "../../../infrastructure/section/form-section"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SendIcon from '@mui/icons-material/Send';
import CheckInfoContainer from "../../../infrastructure/section/check-info-container"
import axios from "../../../services/API";
import CircularProgress from '@mui/material/CircularProgress';
import { getBaseUrl } from '../../../services/selectors/baseUrlSelector'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SuccessStep from "../step-success"
import DoneIcon from '@mui/icons-material/Done';

import './styles.scss'

interface IProps {
    handleBack: () => void
    handleReset: () => void
    userEmployment: any
    isAdaptive?: boolean
}

const StepCheckData = ({ handleBack, handleReset, userEmployment, isAdaptive }: IProps) => {
    const [showModal, setShowModal] = useState(false)
    const [isSending, setIsSending] = useState(false)
    const [agreeWithRules, setAgreeWithRules] = useState(false)
    const [successScreen, setSuccessScreen] = useState(false)
    const [isErrorScreen, setIsErrorScreen] = useState(false)

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const userData = useSelector(getUserDataState);
    const employmentData = useSelector(getEmployment);
    const mainAdress = useSelector(getDataState) // main address
    const additionalAddress = useSelector(getAdressState) //additional address 

    const details = useSelector(getDetailsState)
    const defaultIncommingData = useSelector(getAllIncommingState)
    const baseUrl = useSelector(getBaseUrl)
    const { education } = userEmployment
    const checked = useSelector(getCheckedState)

    const goPage = (page) => {
        dispatch(changePage(page))
    }

    const { education: { name = "" }, workPlaces: { employmentStatus, company, jobTitle, startWorkDate, isOfficial } } = employmentData

    const {
        radiosTraffic,
        dealCost,
        checkedFunds,
        operations,
        target,
        values,
    } = details

    const sendAndReset = () => {
        setIsSending(true)

        const updatedData = {
            ...defaultIncommingData,
            fields: {
                ...defaultIncommingData.fields,
                address: {
                    registrationAddress: {
                        ...mainAdress
                    },
                    residentialAddress: {
                        ...additionalAddress
                    }
                },
                education: {
                    bacalaureat: education
                },
                occupation: {
                    workplaces: { ...employmentData.workPlaces }
                },
                clientInformation: {
                    incomeSources: checkedFunds,
                    monthlyTurnover: radiosTraffic,
                    operationType: operations.checked,
                    target: target.checked,
                    transactionAverage: dealCost,
                },

                contactInfo: {
                    ...defaultIncommingData.fields?.contactInfo,
                    acceptPersonalDataProcessing: true,
                    acceptAspPersonalData: true,
                },
                usa: {
                    ...defaultIncommingData.fields?.usa,

                    fatca: values[1] === YES,
                    payments: values[2] === YES,
                    born: values[3] === YES,
                    power: values[4] === YES,
                    resident: values[5] === YES,
                },
                publicity: {
                    publicPerson: values[6] === YES,
                }
            }
        }

        axios.post(`${baseUrl}/file`, { ...updatedData })
            .then(res => {
                if ((res.status === 200 || res.status === 201)) {
                    enqueueSnackbar(t('Data_has_been_sent'))
                    setSuccessScreen(true)
                    setIsSending(false)
                    setShowModal(false)
                } else {
                    setIsErrorScreen(true)
                    setIsSending(false)
                    setShowModal(false)
                }
            })
            .catch(err => {
                enqueueSnackbar(t("Error"))
            });
    }

    const renderCheckedInfoBlock = (targetArr, arr) => {
        const content = arr.map((i, index) => {
            const title = targetArr[i - 1].name
            return (
                <div key={index}>• {t(title)}</div>
            )
        })
        return content
    }


    const renderModal = () => {
        return (
            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal-content step-8">
                    <div className="left-information-container">

                        <div className="phone-social">
                            <Typography >
                                {t('Send_and_rest_title')}
                            </Typography>
                        </div>
                    </div>

                    <div className='finish-actions'>
                        <div onClick={() => setShowModal(false)} className="modal-prev-btn">
                            {t('Cancel')}
                        </div>
                        <div onClick={() => sendAndReset()} className={`${disabled && "disabled-btn"} next-step-btn`}>
                            {isSending && <CircularProgress className="circular-progress" size={14} />}
                            {t("Finish_and_send")}
                            <SendIcon fontSize="small" sx={{ ml: 2 }} />
                        </div>
                    </div>
                </Box>

            </Modal>
        )
    }


    const renderQuestions = () => {
        return questions && questions.map(({ id, title }, i) => {
            return (
                <div className="info-line">
                    <label>{t(title)}: </label>
                    <span>{t(values[id])}</span>
                </div>
            );
        });
    }

    const today = moment();

    const validAndPast = moment(startWorkDate, 'DD-MM-YYYY', true).isValid() && moment(today).isAfter(startWorkDate)

    const dateStartWork = validAndPast ? moment(startWorkDate).format('L') : t("No_data")

    const hasAdditionalAddress = additionalAddress.locality !== "" ||
        additionalAddress.region !== "" ||
        additionalAddress.street !== "" ||
        additionalAddress.house !== "" ||
        additionalAddress.block !== "" ||
        additionalAddress.flat !== ""

    const showAdditionalAdressContent = (additionalAddress.locality === "" &&
        additionalAddress.region === "" &&
        additionalAddress.street === "" &&
        additionalAddress.house === "" &&
        additionalAddress.block === "" &&
        additionalAddress.flat === "") ? "-" : formatAddress(additionalAddress)

    const educationName = t(educationList.find(el => el.id === Number(education))?.name)

    const disabled = !agreeWithRules;

    const renderMainContent = () => {
        return (
            <>
                <FormSection id="step-right" title={t('Step_9_title')} noPadding>

                    <div className="finish-info-block mt-20">
                        <CheckInfoContainer
                            title={t('Basic_data')}
                        >
                            <div className="info-line">
                                <label>{t('Phone_number')}:</label>
                                <span>{userData.phoneNumber}</span>
                            </div>
                            <div className="info-line">
                                <label>IDNP:</label>
                                <span>{userData.idnp}</span>
                            </div>
                        </CheckInfoContainer>

                        <CheckInfoContainer title={t('IDNP')} >
                            <div className="info-line">
                                <label>{t('Step_2_title')}:</label>
                                <span className="success"><DoneIcon />{isAdaptive ? t("Success_short") : t("Success")}</span>
                            </div>
                        </CheckInfoContainer>

                        <CheckInfoContainer title="Face ID" >
                            <div className="info-line">
                                <label>{t('Face_id_check')}:</label>
                                <span className="success"><DoneIcon />{isAdaptive ? t("Success_short") : t("Success")}</span>
                            </div>
                        </CheckInfoContainer>

                        <CheckInfoContainer title={t("Step_4_title")} {...{ goPage }} isFetch step={4}>
                            <div className="info-line">
                                <label>{t('Address')}:</label>
                                <span>{formatAddress(mainAdress)}</span>
                            </div>
                            {hasAdditionalAddress &&
                                <div className="info-line">
                                    <label>{t('Address_living')}:</label>
                                    <span>{showAdditionalAdressContent}</span>
                                </div>
                            }
                        </CheckInfoContainer>

                        <CheckInfoContainer title={t("Step_5_title")} {...{ goPage }} step={5}>
                            <div className="info-line">
                                <label>{t("Step_5_title")}:</label>
                                <span>{educationName || t("No_data")}</span>
                            </div>
                        </CheckInfoContainer>

                        <CheckInfoContainer title={t("Work")} {...{ goPage }} step={6}>
                            <div className="info-line">
                                <label>{t('Work_place')}:</label>
                                <span>{company || t("No_data")}</span>
                            </div>
                            <div className="info-line">
                                <label>{t('Job_title')}:</label>
                                <span>{jobTitle || t("No_data")}</span>
                            </div>
                            <div className="info-line">
                                <label>{t('Employment')}:</label>
                                <span>{t(employmentStatus) || t("No_data")}</span>
                            </div>
                            <div className="info-line">
                                <label>{t('Start_date')}:</label>
                                <span>{dateStartWork}</span>
                            </div>
                            <div className="info-line">
                                <label>{t("Is_official")}:</label>
                                <span>{isOfficial === "true" ? t("Yes") : isOfficial === "false" ? t("No") : "-"}</span>
                            </div>
                        </CheckInfoContainer>

                        <CheckInfoContainer title={t("Step_7_title")} {...{ goPage }} step={7}>
                            <div className="info-line">
                                <label> {t('Performed_operations')}:</label>
                                <span>{operations.checked.length ? renderCheckedInfoBlock(performedOperations, operations.checked) : "-"}</span>
                            </div>

                            <div className="info-line">
                                <label> {t('Source_of_funds')}:</label>
                                <span>{checkedFunds.length ? renderCheckedInfoBlock(sourcesOfFunds, checkedFunds) : "-"}</span>
                            </div>

                            <div className="info-line">
                                <label> {t('Target_purpose')}:</label>
                                <span>{target.checked.length ? renderCheckedInfoBlock(performedOperations, target.checked) : "-"}</span>
                                {target.other &&
                                    <div className="other-container">
                                        <label>{t('Other')}</label>
                                        {target.other}
                                    </div>}
                            </div>

                            <div className="info-line">
                                <label>{t('Average_monthly_traffic')}:</label>
                                <span>{traffic?.name ? `${t(traffic?.name)}` : "-"}</span>
                            </div>

                            <div className="info-line">
                                <label>{t('Average_trade_value')}:</label>
                                <span>{dealCost ? `${t(deal?.name)}` : "-"}</span>
                            </div>
                        </CheckInfoContainer>

                        <CheckInfoContainer title={t("Step_8_title")} {...{ goPage }} step={8}>
                            {renderQuestions()}
                        </CheckInfoContainer>
                    </div>
                </FormSection>

                <FormGroup className="form-checkbox">
                    <FormControlLabel
                        control={<Checkbox checked={agreeWithRules}
                            onChange={(e) => setAgreeWithRules(!agreeWithRules)} />}
                        label={<div className="form-text-checkbox" dangerouslySetInnerHTML={{ __html: t('Agree_to_rules') }}/>}
                    />
                </FormGroup>

                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mb: 5 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <div className='finish-actions'>
                        <div onClick={() => handleBack()} className="prev-step-btn">
                            <ArrowBackIosIcon fontSize="small" className="details-arrow" />
                            {t('Back_step')}
                        </div>
                        <div onClick={() => setShowModal(true)} className={`${disabled && "disabled-btn"} next-step-btn`}>
                            {t("Finish_and_send")}
                            <SendIcon fontSize="small" sx={{ ml: 2 }} />
                        </div>
                    </div>
                </Box>
            </>
        )
    }

    const traffic = incomeInterval.find(({ id }) => id === Number(radiosTraffic))
    const deal = transactionDeals.find(({ id }) => id === Number(dealCost))
    const content = successScreen || isErrorScreen ? <SuccessStep {...{ handleReset, isErrorScreen }} /> : renderMainContent()
    return (
        <div className="step-container">
            {renderModal()}
            {content}
        </div>
    )
}

export default StepCheckData