import { GET_DICT_COUNTRIES, SET_ALL_DATA } from '../actions';
import { AnyAction } from 'redux'

const initialState = {
    dictCities: []
};

export default function dictionaryReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case GET_DICT_COUNTRIES:
            console.log("GET_DICT_COUNTRIES", action.payload)
            return {
                ...state,
                dictCities: action.payload
            };
        default:
            return state;
    }
}