import React, { useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { incomeInterval } from '../../../../../data/constants'
import { useTranslation } from 'react-i18next';

const TrafficModal = ({ onSave, data, onClose }) => {
    const [radiosTraffic, setRadiosTraffic] = useState(null)
    const { t } = useTranslation();

    useEffect(() => {
        const { radiosTraffic } = data
        radiosTraffic && setRadiosTraffic(radiosTraffic)
    }, [])

    const save = () => {
        const data = { radiosTraffic }
        onSave(data)
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRadiosTraffic((event.target as HTMLInputElement).value);
    };

    return (
        <div className="traffic-container">
            <FormControl>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    className="traffic-radio"
                    value={radiosTraffic}
                >
                    {incomeInterval.map(({ id, name }) => {
                        return (
                            <FormControlLabel
                                key={id}
                                value={id}
                                control={<Radio />}
                                label={t(name)}
                                onChange={handleChange}
                            />
                        )
                    })}
                </RadioGroup>
            </FormControl>

            <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                    onClick={() => onClose()}
                    variant="text"
                    sx={{ 
                        marginRight: '10px',
                    }} >
                    {t('Cancel')}
                </Button>
                <Button
                    onClick={() => save()}
                    variant="contained">
                    {t('Save')}
                </Button>
            </Box>
        </div>
    )
}

export default TrafficModal;