import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { getDataState } from '../../../services/selectors/addressesSelector';
import { getAdressState, getDoubleFetch } from '../../../services/selectors/mainSelector';
import { setAddressDataState, setMainAdress, setEducation, saveFatcaValues, noDoubleFetch, setAddresses, setDetails, editWorkPlace } from '../../../services/actionCreators/mainActionCreator';
import { saveIncommingData } from '../../../services/actionCreators/incommingDataActionCreator';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import FormSection from "../../../infrastructure/section/form-section"
import axios from "../../../services/API";
import ActionButtons from "../../../infrastructure/section/action-buttons-section"
import { AdressIcon } from "../../../infrastructure/components/helpers/adress-icon"
import { AddIcon } from "../../../infrastructure/components/helpers/add-icon"
import {
    YES,
    NO,
    addressFields,
    FIELD_MAX_LENGTH,
    specialCharRegex
} from '../../../data/constants';
import CircularProgress from '@mui/material/CircularProgress';
import { getBaseUrl } from '../../../services/selectors/baseUrlSelector'
import Box from '@mui/material/Box';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import DeleteIcon from '@mui/icons-material/Delete';

import './styles.scss';

const StepAddress = ({ handleNext, handleBack, isAdaptive }) => {
    const dispatch = useDispatch();
    const doubleFetch = useSelector(getDoubleFetch)

    const [isFetching, setIsFetching] = useState(doubleFetch);
    const [error, setError] = useState({});
    const [showAdditionalAddressModal, setIsShowAdditionalAddressModal] = useState(false);
    const [showDeleteAddressModal, setIsShowDeleteAddressModal] = useState(false);

    const data: IAddressesData = useSelector(getDataState);

    const baseUrl = useSelector(getBaseUrl)

    const adressData = useSelector(getAdressState)
    const { locality, region, street, house, block, flat } = adressData
    const emptyTemporaryAddress = { locality: "", region: "", street: "", house: "", block: "", flat: "" }
    const [temporaryAdressData, setTemporaryAdressData] = useState(emptyTemporaryAddress);

    const userHaveAdditionalAdress = locality || region || street || house || block || flat

    const temporaryAddress = `${temporaryAdressData.locality && `${temporaryAdressData.locality},`}${temporaryAdressData.region && `${temporaryAdressData.region},`}${temporaryAdressData.street && `${temporaryAdressData.street},`}${temporaryAdressData.house && `${temporaryAdressData.house},`}${temporaryAdressData.block && `${temporaryAdressData.block},`}${temporaryAdressData.flat && `${temporaryAdressData.flat}`}`

    const { t } = useTranslation();

    useEffect(() => {
        setTemporaryAdressData(adressData)
        doubleFetch && axios.get(`${baseUrl}/file`)
            .then(res => {
                dispatch(noDoubleFetch())
                const { data } = res
                dispatch(saveIncommingData(data))

                const {
                    registrationAddress,
                    residentialAddress
                } = data.fields.address

                onSetRegistrationAddress(registrationAddress)
                onSetAdditionalAddress(residentialAddress)
                setTemporaryAdressData({ ...residentialAddress })
                onCheckIfWeHaveSomeAdditionalInfo(residentialAddress)
                onSetEducation(data.fields)
                onSetWorkPlace(data.fields.occupation)
                onSetIncomeInfo(data.fields.clientInformation)
                onSetFatceInfo(data.fields)
                setIsFetching(false)
            })
            .catch(err => {
                setIsFetching(false)
            });
    }, [])

    const onSetRegistrationAddress = (registrationAddress) => {
        const { locality = "", region = "", street = "", house = "", block = "", flat = "" } = registrationAddress
        dispatch(setAddresses({ locality, region, street, house, block, flat }))
    }
    const onSetAdditionalAddress = (residentialAddress) => {
        const { locality = "", region = "", street = "", house = "", block = "", flat = "" } = residentialAddress
        dispatch(setMainAdress({ locality, region, street, house, block, flat }))
    }
    const onCheckIfWeHaveSomeAdditionalInfo = (residentialAddress) => {
        const { locality = "", region = "", street = "", house = "", block = "", flat = "" } = residentialAddress
        if (locality || region || street || house || block || flat) {
            setChecked(true)
        }
    }
    const onSetEducation = (data) => {
        dispatch(setEducation({ educationLevel: data.education.bacalaureat || { id: null, name: "" } }))
    }
    const onSetWorkPlace = (work) => {
        dispatch(editWorkPlace("company", work.workplaces?.company))
        dispatch(editWorkPlace("employmentStatus", work.workplaces?.employmentStatus))
        dispatch(editWorkPlace("isOfficial", work.workplaces?.isOfficial))
        dispatch(editWorkPlace("jobTitle", work.workplaces?.jobTitle))
        dispatch(editWorkPlace("startWorkDate", work.workplaces?.startWorkDate))
    }
    const onSetIncomeInfo = (clientInformation) => {
        dispatch(setDetails({ radiosTraffic: clientInformation.monthlyTurnover }))
        dispatch(setDetails({ operations: { checked: clientInformation.operationType, other: "" } }))
        dispatch(setDetails({ target: { checked: clientInformation.target, other: "" } }))
        dispatch(setDetails({ dealCost: clientInformation.transactionAverage }))
        dispatch(setDetails({ checkedFunds: clientInformation.incomeSources }))

    }
    const onSetFatceInfo = (fatca) => {
        const fatcaObj = {
            1: fatca.usa.fatca ? YES : NO,
            2: fatca.usa.payments ? YES : NO,
            3: fatca.usa.born ? YES : NO,
            4: fatca.usa.power ? YES : NO,
            5: fatca.usa.resident ? YES : NO,
            6: fatca.publicity.publicPerson ? YES : NO,
        }
        dispatch(saveFatcaValues(fatcaObj))
    }

    const setChecked = (checked) => {
        const obj = {
            id: "checked",
            data: checked
        }
        dispatch(setAddressDataState(obj))
    }

    const saveAdditionalAddress = () => {

        dispatch(setMainAdress({
            "locality": temporaryAdressData.locality,
            "region": temporaryAdressData.region,
            "street": temporaryAdressData.street,
            "house": temporaryAdressData.house,
            "block": temporaryAdressData.block,
            "flat": temporaryAdressData.flat,
        }));
        setIsShowAdditionalAddressModal(false)
    }

    const onCloseModal = () => {
        setTemporaryAdressData(adressData)
        setIsShowAdditionalAddressModal(false)
    }

    const onChangeTemporaryAddress = (state, value) => {

        const firstChar = specialCharRegex.test(value[0]) //to be false
        const lastChar = specialCharRegex.test(value[value.length - 1]) //to be false

        let twoSpecialChars = false

        for (let i = 0; i < value.length; i++) {
            const charIsSpecial = specialCharRegex.test(value[i])
            const prevCharIsSpecial = specialCharRegex.test(value[i - 1])
            if (charIsSpecial && prevCharIsSpecial) {
                twoSpecialChars = true
            }
        }

        if (value.length <= FIELD_MAX_LENGTH || value === "") {
            setTemporaryAdressData({
                ...temporaryAdressData,
                [state]: value
            })

            const errTwoSpecialChars = twoSpecialChars && t('Err_two_special_chars')
            const errFirstCharSpecialChar = firstChar && t('Err_first_char_special_char')
            const errLastCharSpecialChars = lastChar && t('Err_last_char_special_chars')

            const errObj = {
                [state]: {
                    errTwoSpecialChars,
                    errFirstCharSpecialChar,
                    errLastCharSpecialChars
                },
            }
            setError({ ...error, ...errObj })
        }
    }

    const deleteAdditionalAddress = () => {
        dispatch(setMainAdress({
            "locality": "",
            "region": "",
            "street": "",
            "house": "",
            "block": "",
            "flat": "",
        }));
        setIsShowDeleteAddressModal(false)
        setTemporaryAdressData(emptyTemporaryAddress)
    }

    const renderDeleteTemporaryAddress = () => {
        return (
            <Modal
                open={showDeleteAddressModal}
                onClose={() => setIsShowDeleteAddressModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableAutoFocus={true}
            >
                <Box className="modal-content">
                    <Typography variant="h5" className="modal-title">
                        {t('Deleting_temporary_address')}
                    </Typography>
                    {t("Are_you_sure_to_delete_address")} "{temporaryAddress}" ?

                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            onClick={() => setIsShowDeleteAddressModal(false)}
                            sx={{ mr: 1 }}
                        >
                            {t('Cancel')}
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button
                            onClick={() => deleteAdditionalAddress()}
                            variant="contained"
                        >
                            {t('Delete')}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        )
    }

    const renderInputErrorList = (errArr) => {
        const content = errArr.map(err => {
            return (
                <span className="err-line">• {err}</span>
            )
        })

        return content
    }

    const checkError = (state, getOnlyText = false) => {
        if (getOnlyText) {
            let errorText = []
            error[state]?.errFirstCharSpecialChar && errorText.push(t('Err_first_char_special_char'))
            error[state]?.errLastCharSpecialChars && errorText.push(t('Err_last_char_special_chars'))
            error[state]?.errTwoSpecialChars && errorText.push(t('Err_two_special_chars'))

            return renderInputErrorList(errorText)
        } else {
            const isError = error[state]?.errFirstCharSpecialChar ||
                error[state]?.errLastCharSpecialChars ||
                error[state]?.errTwoSpecialChars

            return !!isError
        }
    }

    const checkGlobalError = () => {
        let err = 0

        addressFields.forEach(field => {
            const isError = error[field]?.errFirstCharSpecialChar ||
                error[field]?.errLastCharSpecialChars ||
                error[field]?.errTwoSpecialChars

            isError && err++
        })
        return !!err
    }

    const renderAdditionalAddressModal = () => {
        return (
            <Modal
                open={showAdditionalAddressModal}
                onClose={() => onCloseModal()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableAutoFocus={true}
            >
                <Box className="modal-content">
                    <Typography variant="h5" className="modal-title">
                        {t('Temporary_address')}
                    </Typography>
                    <div className="address-modal-container">
                        <TextField
                            className="input-address-modal"
                            label={t('Locality')}
                            variant="outlined"
                            helperText={checkError("locality", true)}
                            error={checkError("locality")}
                            fullWidth
                            value={temporaryAdressData.locality}
                            onChange={(e) => onChangeTemporaryAddress("locality", e.target.value)}
                            onKeyDown={(event) => {
                                event.stopPropagation();
                            }}
                        />

                        <TextField
                            className="input-address-modal"
                            label={t('Region')}
                            variant="outlined"
                            helperText={checkError("region", true)}
                            error={checkError("region")}
                            value={temporaryAdressData.region}
                            fullWidth
                            onChange={(e) => onChangeTemporaryAddress("region", e.target.value)}
                            onKeyDown={(event) => {
                                event.stopPropagation();
                            }}
                        />
                        <TextField
                            className="input-address-modal"
                            label={t('Street')}
                            variant="outlined"
                            helperText={checkError("street", true)}
                            error={checkError("street")}
                            value={temporaryAdressData.street}
                            fullWidth
                            onChange={(e) => onChangeTemporaryAddress("street", e.target.value)}
                            onKeyDown={(event) => {
                                event.stopPropagation();
                            }}
                        />

                        <TextField
                            className="input-address-modal"
                            label={t('House')}
                            variant="outlined"
                            helperText={checkError("house", true)}
                            error={checkError("house")}
                            value={temporaryAdressData.house}
                            fullWidth
                            onChange={(e) => onChangeTemporaryAddress("house", e.target.value)}
                            onKeyDown={(event) => {
                                event.stopPropagation();
                            }}
                        />

                        <TextField
                            className="input-address-modal"
                            label={t('Block')}
                            variant="outlined"
                            helperText={checkError("block", true)}
                            error={checkError("block")}
                            value={temporaryAdressData.block}
                            fullWidth
                            onChange={(e) => onChangeTemporaryAddress("block", e.target.value)}
                            onKeyDown={(event) => {
                                event.stopPropagation();
                            }}
                        />

                        <TextField
                            className="input-address-modal"
                            label={t('Apartament_and_more_details')}
                            variant="outlined"
                            helperText={checkError("flat", true)}
                            error={checkError("flat")}
                            value={temporaryAdressData.flat}
                            fullWidth
                            onChange={(e) => onChangeTemporaryAddress("flat", e.target.value)}
                            onKeyDown={(event) => {
                                event.stopPropagation();
                            }}
                        />
                    </div>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            onClick={() => onCloseModal()}
                            sx={{ mr: 1 }}
                        >
                            {t('Cancel')}
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button
                            onClick={() => saveAdditionalAddress()}
                            disabled={checkGlobalError()}
                            variant="contained">
                            {t('Save')}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        )
    }

    const setDataAndShowModal = () => {
        setTemporaryAdressData(adressData)
        setIsShowAdditionalAddressModal(true)
    }
    const mainContent = () => {
        return (
            <>
                <div className="default-residence-container">
                    <AdressIcon />
                    <div className="default-residence-wrapper-info">
                        <span>
                            {t('Address')}
                        </span>
                        <div className='residence-info'>
                            {data.locality && `${data.locality}, `}
                            {data.region && `${data.region}, `}
                            {data.street && `${data.street}, `}
                            {data.house && `${data.house}, `}
                            {data.block && `${data.block}, `}
                            {data.flat && `${data.flat},`}
                        </div>

                    </div>
                </div>

                {!userHaveAdditionalAdress &&
                    <>
                        <div className="add-addres-label">{t('Other_address')}</div>
                        <div onClick={() => setIsShowAdditionalAddressModal(true)} className="prev-step-btn">
                            <AddIcon />
                            {t('Add_address')}
                        </div>
                    </>
                }

                {renderAdditionalAddressModal()}
                {renderDeleteTemporaryAddress()}

                {userHaveAdditionalAdress && !showAdditionalAddressModal &&
                    <div className="additional-address-content">
                        <b>{t('Other_address')}</b>
                        <TextField
                            className={`input-address-modal disabled`}
                            label={t('Temporary_address')}
                            variant="outlined"
                            fullWidth
                            disabled
                            value={temporaryAddress}
                            onKeyDown={(event) => {
                                event.stopPropagation();
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AdressIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <div className="edit-address-container">
                            <ModeEditOutlineIcon className="edit-icon" onClick={() => setDataAndShowModal()} />
                            <DeleteIcon className="delete-icon" onClick={() => setIsShowDeleteAddressModal(true)} />
                        </div>
                    </div>


                }
            </>
        )
    }

    return (
        <div className="step-container">
            <FormSection id="step-three" title={t('Step_4_title')}>
                {isFetching ?
                    <div className="loading-container">
                        <CircularProgress className="circular-progress" />
                        <Typography className="text-address">
                            {t('Fetching_data')}
                        </Typography>
                    </div>
                    : mainContent()
                }

            </FormSection>
            <ActionButtons
                {...{
                    handleNext,
                    handleBack,
                    isAdaptive
                }}
                hideBackButton
                nextBtnLabel={t('Next_step')}
            />
        </div>
    );
};

export default StepAddress;
