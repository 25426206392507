import React, { ChangeEvent, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "../../../services/API";
import { useTranslation } from "react-i18next";
import { getBaseUrl } from "../../../services/selectors/baseUrlSelector";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import {
  CONFIRM_BULETIN,
  capitalizeFirstLetter,
} from "../../../data/constants";
import { IStepIDNP } from "../../../interfaces";
import FormSection from "../../../infrastructure/section/form-section";
import ErrorIcon from "@mui/icons-material/Error";
import FrontPassport from "./content/image/front.png";
import BackPassport from "./content/image/back.png";
import Webcam from "react-webcam";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
// import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Typography from "@mui/material/Typography";

import "./styles.scss";

const StepIDNP = (props: IStepIDNP) => {
  const { handleNext } = props;

  const emptyPassport = {
    frontImage: {
      name: "",
      src: "",
    },
    backImage: {
      name: "",
      src: "",
    },
  };
  const { t } = useTranslation();
  const baseUrl = useSelector(getBaseUrl);
  const [isFetching, setIsFetching] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState("");
  const [activePhotoSide, setActivePhotoSide] = useState("");
  const [image, setImage] = useState(emptyPassport);
  const [temporaryImage, setTemporaryImage] = useState(emptyPassport);
  const [cameraAllow, setCameraAllow] = useState(false);
  // const [showCamera, setShowCamera] = useState(false);
  // const [progress, setProgress] = useState(0);
  const webcamRef = useRef(null);

  const cameraWidth = 400;
  const cameraHeight = 400;
  const aspectRatio = cameraWidth / cameraHeight;

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };

  const isMobileDevice = isMobile();

  console.log('isMobileDevice', isMobileDevice);

  const videoConstraints = {
    width: {
      min: cameraWidth,
    },
    height: {
      min: cameraHeight,
    },
    aspectRatio,
    facingMode: isMobileDevice ? "environment" : "user",
  };

  console.log('videoConstraints', videoConstraints);

  // useEffect(() => {

  //   if (showCamera) {

  //     const timer = setInterval(() => {
  //       setProgress((prevProgress) => prevProgress + 20);
  //     }, 1000);
  //     if (progress >= 100) {
  //       handleCaptureScreenshot()
  //       clearInterval(timer);
  //     }
  //     return () => {
  //       clearInterval(timer);
  //     };
  //   }

  //   const keyDownHandler = event => {
  //     if (event.code === 'Enter' || event.code === 'Space') {
  //       event.preventDefault();
  //       handleCaptureScreenshot();
  //     }
  //   };
  //   !image[activePhotoSide]?.src && document.addEventListener('keydown', keyDownHandler);
  // }, [progress, cameraAllow, showCamera]);

  const handleCaptureScreenshot = () => {
    if (webcamRef.current != null) {
      const imageSrc = webcamRef.current.getScreenshot();
      setTemporaryImage({
        ...temporaryImage,
        [activePhotoSide]: { name: "camera-photo", src: imageSrc },
      });
      //setShowCamera(false)
      // setProgress(0)
      setError("");
      //setActivePhotoSide("")
    }
  };

  const startCamera = (side) => {
    setIsFetching(true);
    //setShowCamera(true)
    setActivePhotoSide(side);
    setError("");
    const MediaDevices = navigator.mediaDevices;
    const constraints = {
      video: true,
    };
    MediaDevices.getUserMedia(constraints)
      .then(() => {
        setIsFetching(false);
        setCameraAllow(true);
      })
      .catch(() => {
        setIsFetching(false);
        setCameraAllow(false);
      });
  };

  useEffect(() => {
    const { frontImage, backImage } = image;

    if (frontImage.src.length > 0 && backImage.src.length > 0) {
      setIsSending(true);
      const params = {
        frontImage: frontImage.src
          .replace("data:image/jpeg;base64,", "")
          .replace("data:image/png;base64,", "")
          .replace("data:image/webp;base64,", ""),
        backImage: backImage.src
          .replace("data:image/jpeg;base64,", "")
          .replace("data:image/png;base64,", "")
          .replace("data:image/webp;base64,", ""),
      };
      axios
        .post(`${baseUrl}/passport/check`, { ...params })
        .then((res) => {
          //handleNext()
          if (res.status === 200 || res.status === 201) {
            const isOk = res.data.result === CONFIRM_BULETIN.OK || CONFIRM_BULETIN.ALREADY_STORED;

            if (!isOk) {
              setError(t(capitalizeFirstLetter(res.data.result)));
            } else {
              handleNext();
            }
          } else {
            setError(t("Sent_error"));
          }
          setIsSending(false);
          setIsFetching(false);
        })
        .catch((err) => {
          setIsFetching(false);
        });
    }
  }, [image.frontImage.src, image.backImage.src]);

  const renderSelfieModal = () => {
    return (
      <Modal
        open={cameraAllow}
        onClose={() => setCameraAllow(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus={true}
      >
        <Box className="modal-content">
          <CloseIcon
            onClick={() => setCameraAllow(false)}
            className="close-icon"
          />
          {renderGetPhotoIDNPContainer()}
        </Box>
      </Modal>
    );
  };
  const saveBuletinPhoto = () => {
    setImage({
      ...image,
      [activePhotoSide]: {
        name: "camera-photo",
        src: temporaryImage[activePhotoSide]?.src,
      },
    });
    setCameraAllow(false);
  };

  const modalTitle =
    activePhotoSide === "frontImage" ? t("Front_side") : t("Back_side");
  const modalDescription =
    activePhotoSide === "frontImage" ? t("Front") : t("Back");

  const renderGetPhotoIDNPContainer = () => {
    return (
      <>
        <div className="capture-photo-wrapper">
          <div className="face-id-check-info-text">
            <h2>{modalTitle}</h2>
            <h4>
              {t("Set_photo")} {modalDescription} {t("Set_photo_2")}
            </h4>
          </div>
          {temporaryImage[activePhotoSide]?.src && (
            <img src={temporaryImage[activePhotoSide]?.src} />
          )}
          {!temporaryImage[activePhotoSide]?.src && (
            <Webcam
              className="photo-preview-container"
              ref={webcamRef}
              videoConstraints={videoConstraints}
              width={cameraWidth}
              height={cameraHeight}
              screenshotFormat="image/jpeg"
            />
          )}
          {/* {!image[activePhotoSide]?.src &&
            <Button variant="contained" className='determinate-btn'>
              <LinearProgressWithLabel t={t} value={progress} onClick={() => handleCaptureScreenshot()} />
              <span className="material-symbols-rounded">
                photo_camera
              </span>
            </Button>
          } */}

          <div className="modal-action-buttons">
            {!temporaryImage[activePhotoSide]?.src ? (
              <>
                <Button
                  component="label"
                  className={`primary-button-idnp edit-image`}
                  disabled={isFetching}
                  onClick={() => handleCaptureScreenshot()}
                >
                  {t("Make_photo")}
                </Button>
                <Button
                  component="label"
                  className={`secondary-button-idnp`}
                  onClick={() => setCameraAllow(false)}
                >
                  {t("Cancel")}
                </Button>
              </>
            ) : (
              <>
                <Button
                  component="label"
                  className={`primary-button-idnp edit-image`}
                  disabled={isFetching}
                  onClick={() => saveBuletinPhoto()}
                >
                  {t("Save_photo")}
                </Button>
                <Button
                  component="label"
                  className={`secondary-button-idnp`}
                  onClick={() => setTemporaryImage(emptyPassport)}
                >
                  {t("Change")}
                </Button>
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>, side: string) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];

    const getBase64 = (file, cb) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        cb(reader.result);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    };

    getBase64(file, (result) => {
      setImage({
        ...image,
        [side]: {
          name: file.name,
          src: result,
        },
      });
    });
    setError("");
  };

  const onFileUpload = (event, side) => {
    event && handleFileUpload(event, side);
  };

  const otpCheckFailed = () => {
    return (
      <div className="otp-failed idnp-error">
        <ErrorIcon color="error" />
        <label>{t(error)}</label>
      </div>
    );
  };

  const renderUploadButtons = (side) => {
    return (
      <>
        <Button
          component="label"
          sx={{
            marginLeft: 1,
          }}
          className={`secondary-button-idnp ${image[side].src && "img-done"}`}
          disabled={isFetching}
          onClick={() => startCamera(side)}
        >
          <span className="material-symbols-outlined">photo_camera</span>
          {t("Start_photo")}
        </Button>
        <Button
          component="label"
          sx={{
            marginLeft: 1,
          }}
          className={`secondary-button-idnp ${image[side].src && "img-done"}`}
          disabled={isFetching}
        >
          <span className="material-symbols-outlined">upload</span>
          {t("Upload_passport_photo")}
          <input type="file" hidden onChange={(e) => onFileUpload(e, side)} />
        </Button>
      </>
    );
  };

  const setAnotherPhoto = (side) => {
    startCamera(side);
    setTemporaryImage(emptyPassport);
  };

  const renderUploadedImage = (side) => {
    return (
      <>
        <img src={image[side].src} alt="" />
        {image[side].name && (
          <div className="image-info">
            <span>{image[side].name}</span>
            <CloseIcon
              onClick={() => {
                setImage({ ...image, [side]: { name: "", src: "" } });
                setError("");
              }}
              sx={{ color: "#606060", cursor: "pointer" }}
            />
          </div>
        )}

        <div className="image-action-buttons">
          <Button
            component="label"
            className={`secondary-button-idnp edit-image`}
            disabled={isFetching}
          >
            <span className="material-symbols-outlined">upload</span>
            {t("Download_another_photo")}
            <input type="file" hidden onChange={(e) => onFileUpload(e, side)} />
          </Button>
          <Button
            component="label"
            sx={{
              marginLeft: 1,
            }}
            className={`secondary-button-idnp`}
            disabled={isFetching}
            onClick={() => setAnotherPhoto(side)}
          >
            <span className="material-symbols-outlined">photo_camera</span>
            {t("Make_another_photo")}
          </Button>
          {/* <Button
            component="label"
            className={`secondary-button-idnp edit-image trash-styles`}
            disabled={isFetching}
            onClick={() => setImage({ ...image, [side]: { name: "", src: "" } })}
          >
            <span className="material-symbols-outlined">delete</span>
            {t('Delete')}
          </Button> */}
        </div>
      </>
    );
  };

  const renderChooseFile = () => {
    return (
      <div className="j-center upload-buletin">
        <div className="images-container">
          <span> {t("Upload_indp_face_part")} </span>
          <div className="grid-container">
            <div className="info-photo-wrapper">
              <img src={FrontPassport} alt="FrontPassport" />
            </div>
            <div className="upload-file-wrapper">
              {image.frontImage.src
                ? renderUploadedImage("frontImage")
                : renderUploadButtons("frontImage")}
            </div>
          </div>
        </div>
        <div className="images-container">
          <span> {t("Upload_indp_back_part")} </span>
          <div className="grid-container">
            <div className="info-photo-wrapper">
              <img src={BackPassport} alt="BackPassport" />
            </div>
            <div className="upload-file-wrapper">
              {image.backImage.src
                ? renderUploadedImage("backImage")
                : renderUploadButtons("backImage")}
            </div>
          </div>
        </div>
      </div>
    );
  };

  // const renderNotAllowContainer = () => {
  //   return (
  //     <>
  //       <div className='face-id-check-info-text'>
  //         <p>{t('Allow_camera')}</p>
  //         <p>{t('Allow_camera_important')}</p>
  //       </div>
  //     </>
  //   )
  // }

  // const cameraContent = !isFetching ? cameraAllow ? renderGetPhotoIDNPContainer() : renderNotAllowContainer() : null

  return (
    <div className="step-container">
      {renderSelfieModal()}
      <FormSection id="step-face-id" title={t("Step_2_title")}>
        <div className="face-id-check-info-text">
          <p>{t("Upload_indp_file")}</p>
          <p>{t("Upload_indp_file_important")}</p>
        </div>
        {isFetching ? (
          <div className="loading-container">
            <CircularProgress className="circular-progress" />
          </div>
        ) : (
          renderChooseFile()
        )}
        {/* {!showCamera ? renderChooseFile() : cameraContent} */}
        {/* {renderChooseFile()} */}
        <div className="loading-container error-container">
          {isSending ? (
            <CircularProgress className="circular-progress" />
          ) : (
            error && otpCheckFailed()
          )}
        </div>
      </FormSection>
    </div>
  );
};

export default StepIDNP;
