import React from "react";

export const LogoGeneric = () => {
  return (
    <div className="logo">
      <svg
        width="43"
        height="47"
        viewBox="0 0 43 47"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="LOGO-GENERIC-BANK"
          d="M4.93766 23.2155V32.0489C4.93766 33.8818 6.41725 35.3614 8.25016 35.3614C10.0831 35.3614 11.5627 33.8818 11.5627 32.0489V23.2155C11.5627 21.3826 10.0831 19.903 8.25016 19.903C6.41725 19.903 4.93766 21.3826 4.93766 23.2155ZM18.1877 23.2155V32.0489C18.1877 33.8818 19.6672 35.3614 21.5002 35.3614C23.3331 35.3614 24.8127 33.8818 24.8127 32.0489V23.2155C24.8127 21.3826 23.3331 19.903 21.5002 19.903C19.6672 19.903 18.1877 21.3826 18.1877 23.2155ZM3.8335 46.403H39.1668C40.9997 46.403 42.4793 44.9235 42.4793 43.0905C42.4793 41.2576 40.9997 39.778 39.1668 39.778H3.8335C2.00058 39.778 0.520996 41.2576 0.520996 43.0905C0.520996 44.9235 2.00058 46.403 3.8335 46.403ZM31.4377 23.2155V32.0489C31.4377 33.8818 32.9172 35.3614 34.7502 35.3614C36.5831 35.3614 38.0627 33.8818 38.0627 32.0489V23.2155C38.0627 21.3826 36.5831 19.903 34.7502 19.903C32.9172 19.903 31.4377 21.3826 31.4377 23.2155ZM19.4464 1.11012L2.00058 10.2968C1.09516 10.7605 0.520996 11.7101 0.520996 12.726C0.520996 14.2497 1.75766 15.4864 3.28141 15.4864H39.741C41.2427 15.4864 42.4793 14.2497 42.4793 12.726C42.4793 11.7101 41.9052 10.7605 40.9997 10.2968L23.5539 1.11012C22.2731 0.425534 20.7272 0.425534 19.4464 1.11012Z"
          fill="url(#paint0_linear_1105_11134)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1105_11134"
            x1="-2.5"
            y1="-5"
            x2="73.5"
            y2="62"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2F80ED" />
            <stop offset="1" stop-color="#1C57A7" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};
