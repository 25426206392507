// Used for manipulations with local storage
const PREFERRED_LANGUAGE = "_preferred_language";

export function storePrefLang(lng) {
    localStorage.setItem(PREFERRED_LANGUAGE, lng);
}

export function getPrefLang() {
    return localStorage.getItem(PREFERRED_LANGUAGE);
}

export function isPrefLangPresent() {
    return localStorage.getItem(PREFERRED_LANGUAGE) != null;
}

export function removePrefLang() {
    localStorage.removeItem(PREFERRED_LANGUAGE);
}