import { RootState } from '../../interfaces'

const getMainReducerState = (state: RootState) => {
    return state?.addressesReducer;
};

export const getDataState = (state: RootState) => {
    return getMainReducerState(state).data || [];
};

export const getCheckedState = (state: RootState) => {
    return getMainReducerState(state).checked || false;
};