import React, { ChangeEvent, useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from "../../../services/API";
import { useTranslation } from 'react-i18next';
import { getCameraScreenShot } from '../../../services/selectors/mainSelector'
import { getBaseUrl } from '../../../services/selectors/baseUrlSelector'
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import Webcam from "react-webcam";
import Button from '@mui/material/Button';
import { enqueueSnackbar } from 'notistack';
import { IStepFaceID } from '../../../interfaces';
import {
  saveCameraScreenShot
} from '../../../services/actionCreators/mainActionCreator';
import FormSection from "../../../infrastructure/section/form-section"

import './styles.scss';
import { deleteDataLS } from '../../../infrastructure/components/helpers/localstorage';

function LinearProgressWithLabel({ t, value, onClick }) {
  return (
    <div className="photo-progress-view-info-text" onClick={onClick}>
      <span>{t("Face_id_start_in")}</span>
      <span>({`${Math.round((100 - value) / 20)}`})</span>
    </div>

  );
}

const StepFaceId = (props: IStepFaceID) => {
  const {
    userData: { idnp },
    handleNext,
    isAdaptive
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cameraScreenShot = useSelector(getCameraScreenShot)
  const baseUrl = useSelector(getBaseUrl)
  // const token = useSelector(getToken)

  const [isSend, setIsSend] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isSuccessSend, setIsSuccessSend] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [image, setImage] = useState(cameraScreenShot);
  const [cameraAllow, setCameraAllow] = useState(false);
  const [progress, setProgress] = useState(0);
  const [filename, setFilename] = useState(null);
  const [isIDNPNotFound, setIsIDNPNotFound] = useState(false)

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];

    const getBase64 = (file, cb) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        cb(reader.result)
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }

    getBase64(file, (result) => {
      setImage(result)
      sendScreenshot(result)
    });

  };

  const webcamRef = useRef(null);

  useEffect(() => {

    if (cameraScreenShot) {
      setImage(cameraScreenShot)
      setCameraAllow(true)
    }

    if (cameraAllow) {

      const timer = setInterval(() => {
        setProgress((prevProgress) => prevProgress + 20);
      }, 1000);
      if (progress >= 100) {
        handleCaptureScreenshot()
        clearInterval(timer);
      }
      return () => {
        clearInterval(timer);
      };
    }

    const keyDownHandler = event => {
      if (event.code === 'Enter' || event.code === 'Space') {
        event.preventDefault();
        handleCaptureScreenshot();
      }
    };
    !image && document.addEventListener('keydown', keyDownHandler);
  }, [progress, cameraAllow, cameraScreenShot]);

  const cameraWidth = 300;
  const cameraHeight = 400;
  const aspectRatio = cameraWidth / cameraHeight;

  const videoConstraints = {
    width: {
      min: cameraWidth
    },
    height: {
      min: cameraHeight
    },
    aspectRatio,
    facingMode: "user",
  };

  const startCamera = () => {
    setIsFetching(true)
    const MediaDevices = navigator.mediaDevices;
    const constraints = {
      video: true,
    };
    MediaDevices.getUserMedia(constraints).then(() => {
      setIsFetching(false)
      setCameraAllow(true)
    }).catch(() => {
      setIsFetching(false)
      setCameraAllow(false)
    });

  }

  const handleCaptureScreenshot = () => {
    if (webcamRef.current != null) {
      const imageSrc = webcamRef.current.getScreenshot();
      setImage(imageSrc);
      sendScreenshot(imageSrc)
    }
  }

  const resetImageAndStartTimer = () => {
    setImage(undefined)
    setProgress(0)
    dispatch(saveCameraScreenShot(null))
    enqueueSnackbar(t("Face_id_photo_re_photo_notofication"))
    setIsSend(false)
    setIsError(false)
  }

  const sendScreenshot = (imageBase64) => {
    setIsFetching(true)

    dispatch(saveCameraScreenShot(imageBase64))

    const params = {
      // idnp: mockIdnp,
      // phone: `${PHONE_PREFIX}${mockPhone}`,
      // image: mockPhoto,
      image: imageBase64.replace('data:image/jpeg;base64,', "").replace('data:image/webp;base64,', "")
      //
      // otpPassword: "11111",
      // phone: `${PHONE_PREFIX}${phoneNumber}`,
    };

    axios.post(`${baseUrl}/face/idnp`, { ...params })
      .then(res => {
        if ((res.status === 200 || res.status === 201) && res.data.result === 'ok') {
          dispatch(saveCameraScreenShot(imageBase64))
          enqueueSnackbar(t("Face_id_photo_send_notofication"))
          setIsSend(true)
          setIsSuccessSend(true)
          handleNext()
        } else if (res.status === 200 && res.data.result === 'quality') {
          setErrorMessage('Face_id_error_quality')
          setIsSuccessSend(false)
          setIsError(true)
        } else if (res.status === 200 && res.data.result === 'mismatch') {
          setErrorMessage('Face_id_error_mismatch')
          setIsSuccessSend(false)
          setIsError(true)
        } else if (res.status === 200 && res.data.result === 'liveness') {
          setErrorMessage('Face_id_error_liveness')
          setIsSuccessSend(false)
          setIsError(true)
        } else if (res.status === 200 && res.data.result === 'idnp_not_found') {
          setErrorMessage('Face_id_error_idnp_not_found')
          setIsIDNPNotFound(true)
          setIsSuccessSend(false)
          setIsError(true)
        }
        else {
          setErrorMessage(`${t('Face_id_error')}`)
          setIsError(true)
        }

        setIsFetching(false)

      })
      .catch(err => {
        setIsFetching(false)
      });

  }

  const clearDataAndGoToIndex = () => {
    window.location.href = "/";
    deleteDataLS()
  }

  const renderGetSelfieContainer = () => {
    return (
      <>
        <div className='face-id-check-info-text'>
          <p>{t('Allow_camera')}</p>
          <p>{t('Allow_camera_important')}</p>
        </div>
        <div className="capture-photo-wrapper">
          {image && <img src={image} />}
          {!image &&
            <Webcam
              className="photo-preview-container"
              ref={webcamRef}
              videoConstraints={videoConstraints}
              width={cameraWidth}
              height={cameraHeight}
              screenshotFormat="image/jpeg"
            />
          }
          {!image &&
            <Button variant="contained" className='determinate-btn'>
              <LinearProgressWithLabel t={t} value={progress} onClick={() => handleCaptureScreenshot()} />
              <span className="material-symbols-rounded">
                photo_camera
              </span>
            </Button>
          }

          <div className="action-buttons">
            {image &&
              <>
                <Button onClick={() => resetImageAndStartTimer()} variant="outlined" disabled={isSend} className='outlined-btn mr-10'>
                  <span className="material-symbols-rounded">
                    photo_camera
                  </span>
                  {t('Reset')}
                </Button>
                <Button disabled={isSend} onClick={() => sendScreenshot(image)} variant="contained" className='determinate-btn'>
                  {t('Send')}
                </Button>
              </>
            }
            {/* {renderChooseFile()} */}
          </div>
        </div>
      </>
    )
  }

  const renderNotAllowContainer = () => {
    return (
      <>
        <div className='face-id-check-info-text'>
          <p>{t('Allow_camera')}</p>
          <p>{t('Allow_camera_important')}</p>
        </div>
        <span> {filename} </span>
      </>
    )
  }

  const successContent = () => {
    return (
      <div className='info-message-container'>
        <span className="material-symbols-rounded">
          check
        </span>
        {t('Face_id_success')}
      </div>
    )
  }

  const errorContent = () => {
    return (
      <div>
        <div className='info-message-container-error'>
          <span className="material-symbols-rounded">
            error
          </span>
          {errorMessage && <div className="error"> {t(errorMessage)} </div>}
        </div>
        <div className='flex-center'>
          {isIDNPNotFound ?
            <Button onClick={() => clearDataAndGoToIndex()} variant="outlined" disabled={isSend} className='outlined-btn'>
              <span className="material-symbols-rounded">
                <EditIcon />
              </span>
              {t('Re_enter_idnp')}
            </Button>
            :
            <Button onClick={() => resetImageAndStartTimer()} variant="outlined" disabled={isSend} className='outlined-btn'>
              <span className="material-symbols-rounded">
                photo_camera
              </span>
              {t('Reset')}
            </Button>
          }
        </div>
      </div>
    )
  }

  const onFileUpload = (event) => {
    event && handleFileUpload(event)
  }

  const test = () => {
    setCameraAllow(false)
  }

  const renderChooseFile = () => {
    return (
      <div className='j-center'>
        {!cameraAllow && <div onClick={() => startCamera()} className={`${isFetching && "disabled"} secondary-button`}>
          <span className="material-symbols-rounded">
            photo_camera
          </span>
          {t('Start_camera')}
        </div>}
        {/* <Button
          variant="outlined"
          component="label"
          startIcon={<UploadFileIcon />}
          sx={{
            marginLeft: 1
          }}
          className='outlined-btn'
          disabled={isFetching}
        >
          {t('Upload_file')}
          <input type="file" accept=".jpeg" hidden onChange={(e) => onFileUpload(e)} onClick={() => test()}/>
        </Button> */}
      </div>
    )
  }

  const cameraContent = !isFetching ? cameraAllow ? renderGetSelfieContainer() : renderNotAllowContainer() : null

  return (
    <div className="step-container">
      <FormSection id="step-face-id" title={t('Step_2_title')}>
        {!isSuccessSend && !isError ? cameraContent : isError ? errorContent() : successContent()}
        {isFetching &&
          <div className="loading-container">
            <CircularProgress className="circular-progress" />
          </div>
        }
        {!isSuccessSend && renderChooseFile()}

      </FormSection>

      {/* comments this because for now we don't need to use next button on this steps */}
      {/* <ActionButtons
        {...{
          disabled,
          handleNext
        }}
        hideBackButton
      /> */}
    </div>
  );
};

export default StepFaceId;
