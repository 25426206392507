import React from 'react';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { educationList } from "../../../../data/constants"
import { useTranslation } from 'react-i18next';

import './styles.scss';

const UserModalContainer = ({ education, save }) => {
    const { t } = useTranslation();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        save((event.target as HTMLInputElement).value);
    };
    console.log("educationList", educationList, education)
    return (
        <Box >
            <div className="address-modal-container  employement-modal">
                <FormControl>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        className="education-radio"
                        value={education}
                    >
                        {educationList.map(({ id, name }) => {
                            return (
                                <FormControlLabel
                                    key={id}
                                    value={id}
                                    control={<Radio />}
                                    label={t(name)}
                                    onChange={handleChange}
                                    className={`custom-education ${id === Number(education) && "checked-education"}`}
                                />
                            )
                        })}
                    </RadioGroup>
                </FormControl>
                {/* <Autocomplete
                    id="country-select-demo"
                    options={educationList}
                    autoHighlight
                    value={education}
                    onChange={(e, v) => save(v)}
                    getOptionLabel={(option) => t(option.name)}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {t(option.name)}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t('Select_education_level')}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                            onKeyDown={(event) => {
                                event.stopPropagation();
                            }}
                        />
                    )}
                /> */}
            </div>
        </Box>
    );
};

export default UserModalContainer;
