import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { performedOperations } from '../../../../../data/constants'
import { useTranslation } from 'react-i18next';
import { LegendToggleOutlined } from '@mui/icons-material';

const CompletedModal = ({ data, type, onSave, onClose }) => {
    const [checked, setChecked] = useState([]);
    const [other, setOther] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        const key = getKey()

        if (data[key]) {
            const { checked, other } = data[key];

            checked && setChecked(checked);
            other && setOther(other);
        }
    }, []);

    const getKey = () => {
        let key = "operations"

        if (type === 2) key = "target"
        return key
    }

    const handleToggle = (value: number) => () => {
        const checkedIndex = checked.indexOf(value);
        const arr = [...checked];

        checkedIndex === -1 ? arr.push(value) : arr.splice(checkedIndex, 1);
        setChecked(arr);
    };

    const save = () => {
        const key = getKey();

        const data = {
            [key]: {
                checked,
                other
            }
        };
        onSave(data);
    };

    return (
        <>
            <List dense>
                {performedOperations.map(({ name, id }) => {
                    const labelId = `checkbox-list-secondary-label-${id}`;
                    return (
                        <ListItem
                            key={id}
                            className="list-item"
                        >
                            <Checkbox
                                edge="end"
                                onChange={handleToggle(id)}
                                checked={checked.indexOf(id) !== -1}
                                inputProps={{ 'aria-labelledby': labelId }}
                                sx={{ 
                                    padding: '0px',
                                    marginRight: '12px',
                                }}
                            />
                            <ListItemButton disableGutters className='list-modal-button' onClick={handleToggle(id)}>
                                <ListItemText 
                                    id={labelId} 
                                    primary={t(name)} 
                                    sx={{ 
                                        '& span': {
                                            fontFamily: 'OnestRegular',
                                            color: '#202020',
                                            fontSize: '14px'
                                        },
                                    }} />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>

            <TextField
                fullWidth
                className="text-input"
                id="outlined-basic"
                variant="outlined"
                // className="input-address-modal input-country-input"
                label={t("Other")}
                value={other}
                onChange={e => setOther(e.target.value)}
                onKeyDown={(event) => {
                    event.stopPropagation();
                }}
            ></TextField>

            <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    onClick={() => onClose()}
                    variant="text"
                    sx={{ 
                        marginRight: '10px',
                    }} >
                    {t('Cancel')}
                </Button>
                <Button
                    onClick={() => save()}
                    variant="contained">
                    {t('Save')}
                </Button>
            </Box>
        </>
    );
};

export default CompletedModal;