import React from 'react';
import { IActionButtonsSectionProps } from "./types"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button } from 'finergy-kit';

import './styles.scss';

function ActionButtons({ handleNext, handleBack, hideBackButton = false, disabled, backBtnLabel, nextBtnLabel }: IActionButtonsSectionProps) {

    return (
        <div className={`action-buttons ${hideBackButton && "fl-end"}`}>
            {!hideBackButton &&
                <Button
                    label={backBtnLabel}
                    variant="primary"
                    size="l"
                    iconPosition="right"
                    icon={<ArrowBackIosIcon fontSize="small" className="details-arrow" />}
                    onClick={() => handleBack()}
                    className={`prev-step-btn color-row`}
                />
            }
            <Button
                label={nextBtnLabel}
                variant="primary"
                size="l"
                iconPosition="right"
                disabled={disabled}
                icon={<ArrowForwardIosIcon fontSize="small" className="details-arrow" />}
                onClick={() => handleNext()}
                className={`${disabled && "disabled-btn"} next-step-btn`}
            />
        </div>
    );
};

export default ActionButtons;