import React from 'react';

export const Triangle = () => {
    return (
        <div className="triangle">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.15674 0.343262L12.6589 7.35767L8.15674 15.6567L0.500007 7.99999L8.15674 0.343262Z" fill="white" />
                <rect x="12.2407" y="2" width="12" height="4.42712" transform="rotate(90 12.2407 2)" fill="white" />
            </svg>
        </div>
    );
};