import { ADD_DETAILS, SET_ALL_DATA, SAVE_FATCA_VALUES, SET_FATCA_DETAILS } from '../actions';
import { AnyAction } from 'redux'

const initialState = {
    data: {
        checked: [],
        other: "",
        values: {},
        address: "",
        citizenship: "",
        publicFigure: false,
        FATCA: false,
        radiosTraffic: "",
        postCode: "",
        ssn: "",
        dealCost: "",
        checkedFunds: [],
        operations: {
            checked: [],
            other: ""
        },
        target: {
            checked: [],
            other: ""
        },
    }
};

export default function detailsReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case SET_ALL_DATA:
            return { ...(action.payload?.detailsReducer || state) }
        case ADD_DETAILS:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload
                }
            };
        case SAVE_FATCA_VALUES:
            return {
                //...state,
                data: {
                    ...state.data,
                    values: action.payload
                }
            };

        case SET_FATCA_DETAILS:
            return {
                //...state,
                data: {
                    ...state.data,
                    values: action.payload
                }
            };
        default:
            return state;
    }
}