import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import DoneIcon from '@mui/icons-material/Done';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SchoolIcon from '@mui/icons-material/School';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { steps } from '../../data/constants';
import './css/stepper.scss'
import { Typography } from '@mui/material';

interface IProps {
    activeStep: number
    orientation?: any
}

const StepperConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        // top: 20,
        left: 'calc(-50% + 35px)',
        right: 'calc(50% + 35px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#21bb77',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#21bb77',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
    ({ theme, ownerState }) => ({
        zIndex: 1,
        color: '#fff',
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center'
    }),
);

const StepIcon = (props: StepIconProps) => {
    const { active, completed, className, icon } = props;

    const icons = {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
        7: "7",
        8: "8",
        9: "9",
        10: "10",
    };

    const currElement = icons[String(icon)]

    return (
        <QontoStepIconRoot
            ownerState={{ active }}
            className={`${className} ${active && 'stepper-active'} ${completed && 'stepper-completed'}`

            }>
            {completed ? (
                <div className="stepper-item-wrapper">
                    <div className="stepper-icon-block">
                        <DoneIcon className="QontoStepIcon-completedIcon" />
                    </div>
                    <div className='steper-divider-completed' />
                </div>
            ) : (
                <div className="stepper-item-wrapper">
                    <div className="stepper-icon-block">
                        {currElement}
                    </div>
                    <div className='steper-divider' />
                </div>
            )
            }
        </QontoStepIconRoot>
    );
}

const StepperComponent = ({ activeStep, orientation = "vertical" }: IProps) => {
    const { t } = useTranslation();
    return (
        <div className="stepper-container" id="stepperScroll">
            <Stack spacing={4} className="stepper-stack">
                <Stepper alternativeLabel orientation={orientation} {...{ activeStep }} connector={<StepperConnector />} className="stepper-steps-container">
                    {steps.map(({ label, title }, index) => {
                        // const btnText = (index === activeStep && t('In_process')) ||
                        //     (index < activeStep ? t('Done') : t('In_waiting'))
                        // const btnClass = (index === activeStep && "btn-in-progress") ||
                        //     (index < activeStep ? "btn-completed" : "btn-pending")
                        return (
                            <Step key={label} className={`step ${activeStep > index && 'step-completed'}`}>
                                <StepLabel StepIconComponent={StepIcon} className="step-label">
                                    <Typography className="label-title">{t(`Step_${title}`)}</Typography>
                                </StepLabel>
                            </Step>
                        )
                    })}
                </Stepper>
            </Stack>
        </div>
    )
}

export default StepperComponent;