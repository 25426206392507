import React, { useState, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import {
  getUserDataState,
  getIsLoading,
  getEmployment,
} from "./services/selectors/mainSelector";
import { getCurrentLanguage } from "./services/selectors/languageSelector";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import StepOtp from "./pages/steps/step-otp";
import StepAuth from "./pages/steps/step-auth";
import StepIDNP from "./pages/steps/step-idnp";
import StepFaceId from "./pages/steps/step-face-id";
import StepAddress from "./pages/steps/step-address";
import StepWork from "./pages/steps/step-work";
import StepEducation from "./pages/steps/step-education";
import StepIncome from "./pages/steps/step-income";
import StepFatca from "./pages/steps/step-fatca";
import StepCheckData from "./pages/steps/step-check-data";
import Modal from "@mui/material/Modal";
import {
  saveDataLS,
  loadState,
  deleteDataLS,
} from "./infrastructure/components/helpers/localstorage";
import {
  setAllData,
  changePage,
} from "./services/actionCreators/mainActionCreator";
import { saveBaseUrl } from "./services/actionCreators/baseUrlActionCreator";
import { getPage } from "./services/selectors/mainSelector";
import { enqueueSnackbar } from "notistack";
import Header from "./infrastructure/components/header";
import StepperComponent from "./pages/stepper";
import { ADATIVE_WIDTH } from "./data/constants";

import "./styles.scss";
const config = require("./config.json");

const App = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const pjson = require("../package.json");
  const userData = useSelector(getUserDataState);

  const storeData = useSelector((state) => state);
  const userEmployment = useSelector(getEmployment);
  const isLoading = useSelector(getIsLoading);
  const [opened, setOpened] = useState(false);
  const [approveStepTwo, setApproveStepTwo] = useState(false);
  const [browserWidth, setBrowserWidth] = useState(0);

  const isAdaptive = browserWidth <= ADATIVE_WIDTH;
  const activeStep = useSelector(getPage);
  const element = document.getElementById("stepperScroll");

  const handleResize = () => {
    const w = window.innerWidth;
    setBrowserWidth(w);
  };

  element &&
    (activeStep || activeStep === 0) &&
    element.scrollTo({
      top: 0,
      left: activeStep * 40,
      behavior: "smooth",
    });

  useEffect(() => {
    const localStorage = loadState();
    if (localStorage) setOpened(true);

    dispatch(saveBaseUrl(config.serviceURL));

    setBrowserWidth(window.innerWidth);
    window.addEventListener("resize", handleResize, { passive: true });

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  const setActiveStep = (page) => {
    dispatch(changePage(page));
  };

  const handleNext = () => {
    saveDataLS(storeData);
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleReset = () => {
    deleteDataLS();
    window.location.reload();
  };

  const recoverData = () => {
    setApproveStepTwo(true);
    dispatch(setAllData());
    setOpened(false);
    enqueueSnackbar(t("Data_was_restored"));
  };

  const cancelData = () => {
    deleteDataLS();
    setOpened(false);
  };

  const renderStepItemContent = (step: number) => {
    let component;

    const commonProps = {
      userData,
      setApproveStepTwo,
      approveStepTwo,
      setActiveStep,
      handleNext,
      handleBack,
      handleReset,
      isAdaptive,
      userEmployment,
    };

    switch (step) {
      case 0:
        component = <StepAuth {...commonProps} />;
        break;
      case 1:
        component = <StepOtp {...commonProps} />;
        break;
      case 2:
        component = <StepIDNP {...commonProps} />;
        break;
      case 3:
        component = <StepFaceId {...commonProps} />;
        break;
      case 4:
        component = <StepAddress {...commonProps} />;
        break;
      case 5:
        component = <StepEducation {...commonProps} />;
        break;
      case 6:
        component = <StepWork {...commonProps} />;
        break;
      case 7:
        component = <StepIncome {...commonProps} />;
        break;
      case 8:
        component = <StepFatca {...commonProps} />;
        break;
      default:
        component = <StepCheckData {...commonProps} />;
        break;
    }

    return component;
  };

  const renderSteperContent = () => {
    return (
      <Fragment>
        <div className="main-container">
          <StepperComponent {...{ activeStep }} />
          <div className="step-content">
            {renderStepItemContent(activeStep)}
          </div>
        </div>
      </Fragment>
    );
  };

  const renderModal = () => {
    return (
      <Modal
        open={opened}
        onClose={() => setOpened(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-content">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t("Restore_all_data")}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 3 }}>
            <div className="finish-actions">
              <div onClick={cancelData} className="modal-prev-btn">
                {t("Cancel")}
              </div>
              <div onClick={recoverData} className="next-step-btn">
                {t("Restore")}
              </div>
            </div>
          </Box>
        </Box>
      </Modal>
    );
  };

  const content = !isLoading ? (
    renderSteperContent()
  ) : (
    <CircularProgress size={24} className="is-loading" />
  );

  return (
    <>
      <div className="kyc-container" id="kyc-container">
        {renderModal()}
        <Header {...{ activeStep, isAdaptive }} />
        {content}
        <Typography style={{ color: "#5B636D", fontSize: 12, marginTop: 20 }}>
          {" "}
          v.{pjson.version}
        </Typography>
      </div>
    </>
  );
};

export default App;
