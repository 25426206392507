import { createStore, applyMiddleware, combineReducers } from 'redux';
import promise from 'redux-promise';
import thunk from 'redux-thunk';

import { composeWithDevTools } from 'redux-devtools-extension';
import mainReducer from './reducers/mainReducer';
import addressesReducer from './reducers/addressesReducer';
import detailsReducer from './reducers/detailsReducer';
import prefsReducer from './reducers/prefsReducer'
import dictionaryReducer from './reducers/dictionaryReducer'
import incommingDataReducer from './reducers/incommingDataReducer'
import saveBaseUrlReducer from './reducers/saveBaseUrlReducer'

const combinedReducer = combineReducers({
    mainReducer,
    addressesReducer,
    detailsReducer,
    dictionaryReducer,
    prefsReducer,
    incommingDataReducer,
    saveBaseUrlReducer
});

const masterReducer = (state, action) => {
    return combinedReducer(state, action);
};

let middleware = [thunk, promise];

if (process.env.NODE_ENV !== 'production') {
    middleware = [...middleware];
}
const composeEnhancers = composeWithDevTools({
    trace: true
});
const Store = createStore(masterReducer, composeEnhancers(applyMiddleware(...middleware)));
export type RootGetState = ReturnType<typeof Store.getState>

export default Store;