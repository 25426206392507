import {
    BASE_URL
} from '../actions';

import { AnyAction } from 'redux'

const initialState = {
    baseUrl: ""
};

export default function saveBaseUrlReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case BASE_URL: {
            return {
                ...state,
                baseUrl: action.payload
            }
        }

        default:
            return state;
    }
}
