import Store from "./store"
import axios from "axios";


Store.subscribe(listener);

function select(state) {
    return state.mainReducer;
}

function listener() {
    const state = select(Store.getState()) || null;

    // axios.defaults.headers.common['Authorization'] = "Bearer " + token ;
    axios.defaults.headers.common['AuthToken'] = state.token ;
}

axios.defaults.baseURL = process.env.PUBLIC_URL;

axios.interceptors.response.use(response => response,
    error => {
        if (error.response.status === 401) {
            //TO DO: later need to logic with logout
            console.log("Error and logout")
        }
        return error;
    });

export default axios;
